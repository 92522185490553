var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 ma-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{class:`${
        _vm.$vuetify.theme.isDark ? 'grey darken-4' : 'grey lighten-4'
      } pa-0`,attrs:{"cols":"12"}},[_c('v-row',{staticClass:"px-0",attrs:{"dense":""}},[_c('v-col',{class:`${
            _vm.$vuetify.theme.isDark ? 'grey darken-4' : 'grey lighten-4'
          } pt-0`,attrs:{"cols":"12","align-self":"start"}},[_c('v-divider')],1),_c('v-col',{class:`${
            _vm.$vuetify.theme.isDark ? 'grey darken-4' : 'grey lighten-4'
          } pb-0`,attrs:{"cols":"12","align-self":"end"}},[_c('v-divider')],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }