<template>
  <v-container fluid class="pa-0 fill-height">
    <v-skeleton-loader
      style="width: 100%"
      class="fill-height"
      :loading="status === 'fetching'"
      type="card, list-item-avatar-two-line, card, card, card"
    >
      <!-- this empty div is to make the skeleton loader work, the component element under the div is not triggering the stop of the sekeleton loader -->
      <div></div>
      <component
        v-bind="data"
        :is="$store.state.contentpage.config.component"
      ></component>
    </v-skeleton-loader>
  </v-container>
</template>
<script>
//import { createStyleSheet } from '@/util/helpers'
import { mapState, mapActions } from "vuex";
import LandingPageV1 from "@/components/contentpage/LandingPageV1";
import DoubleListV1 from "@/components/contentpage/DoubleListV1";
import SingleListV1 from "@/components/contentpage/SingleListV1";
import SingleItemV1 from "@/components/contentpage/SingleItemV1";
import ImageGallery from "@/components/contentpage/ImageGallery";
import PicturePoolGallery from "@/components/contentpage/PicturePoolGallery";
import RoomService from "@/components/roomControl/RoomService";
import SingleListV2 from "@/components/contentpage/SingleListV2";
import DestinationOne from "@/components/contentpage/DestinationOne";
import WebSip from "@/components/contentpage/WebSip";
import WebSipRtc from "@/components/contentpage/WebSipRtc";
import DoubleListAlice from "@/components/contentpage/DoubleListAlice";
import PicturePoolGalleryPdf from "@/components/contentpage/PicturePoolGalleryPdf";
export default {
  name: "ContentPage",
  data() {
    return {
      isMounted: false,
      activeContentPage: undefined,
    };
  },
  components: {
    LandingPageV1,
    DoubleListV1,
    SingleListV1,
    SingleItemV1,
    ImageGallery,
    PicturePoolGallery,
    RoomService,
    SingleListV2,
    DestinationOne,
    WebSip,
    WebSipRtc,
    DoubleListAlice,
    PicturePoolGalleryPdf,
  },
  computed: {
    ...mapState({
      isFetching: ({ contentpage }) => contentpage.isFetching,
      status: ({ contentpage }) => contentpage.status,
      template: ({ contentpage }) => contentpage.template,
      script: ({ contentpage }) => contentpage.script,
      style: ({ contentpage }) => contentpage.style,
      data: ({ contentpage }) => contentpage.data,
      dataMap: ({ contentpage }) => contentpage.dataMap,
      skinId: ({ session }) => session.skinId,
      authResult: ({ session }) => session.authResult,
      mediaHeight: (state) => state.mediaHeight,
      theme: ({ theme }) => theme.theme,
    }),
  },
  watch: {
    status(val) {
      if (val === "success") {
        if (this.activeContentPage) {
          this.activeContentPage.$destroy();
          this.$el.innerHTML = '<div id="contentpage_area_inner"></div>';
        }
      } else if (val === "failure") {
        this.showOkMessage({
          message: this.$t("general.noContentOpeningModule"),
          onClose: () => {
            this.$router.back();
          },
        });
      }
    },
    "$route.params.cpId"(newVal) {
      const basePath =
        this.$config.managerSkins +
        this.authResult.skin.project_id +
        "/" +
        this.skinId +
        "/oneapp/jscp/";
      this.getData({ id: newVal, basePath });
    },
  },
  mounted() {
    this.isMounted = true;
    const basePath =
      this.$config.managerSkins +
      this.authResult.skin.project_id +
      "/" +
      this.skinId +
      "/oneapp/jscp/";

    this.getData({ id: this.$route.params.cpId, basePath });
  },
  methods: {
    ...mapActions("contentpage", ["getData"]),
    ...mapActions("modal", ["showOkMessage"]),
    close() {
      this.$router.back();
    },
  },
};
</script>
