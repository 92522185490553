<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row no-gutters>
      <v-col
        :class="`${
          $vuetify.theme.isDark ? 'grey darken-4' : 'grey lighten-4'
        } pa-0`"
        cols="12"
      >
        <v-row class="px-0" dense>
          <v-col
            cols="12"
            :class="`${
              $vuetify.theme.isDark ? 'grey darken-4' : 'grey lighten-4'
            } pt-0`"
            align-self="start"
          >
            <v-divider />
          </v-col>
          <v-col
            cols="12"
            :class="`${
              $vuetify.theme.isDark ? 'grey darken-4' : 'grey lighten-4'
            } pb-0`"
            align-self="end"
          >
            <v-divider />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>
