<template>
  <v-btn
    outlined
    depressed
    :class="state ? 'primary white--text' : 'outlined'"
    @click="$emit('update:state', !state)"
    icon
  >
    {{ $t("roomservice.weekdays." + weekday) }}
  </v-btn>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["state", "weekday"],
};
</script>
