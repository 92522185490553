<template>
  <v-container fluid class="pa-0 ma-0">
    <template v-if="header && header.backgroundImage">
      <v-img
        ref="img"
        :src="
          $config.managerMedia +
          header.backgroundImage +
          `?resize&width=${$store.state.mediaWidth}`
        "
        height="300px"
        dark
      >
        <v-row align="end" v-if="header.Header" class="fill-height">
          <v-container class="py-0">
            <v-card-title class="white--text">
              <div class="display-1">{{ header.Header }}</div>
            </v-card-title>
          </v-container>
        </v-row>
      </v-img>
    </template>
    <v-card-text v-if="header.Description">
      {{ header.Description }}
    </v-card-text>
    <v-list>
      <v-list-item
        :key="item.Tel + item.Text"
        :href="`tel:${item.Tel}`"
        v-for="item in list"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ item.Text }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ item.Tel }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon color="primary">
            <v-icon>mdi-phone</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    header: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
