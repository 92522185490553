<template>
  <v-container fluid>
    <v-card class="mb-5" outlined>
      <v-card-title
        style="word-break: break-word"
        primary-title
        v-html="Header"
      ></v-card-title>
      <v-card-text>
        <div v-html="Description" style="white-space: pre-wrap"></div>
        <v-btn
          class="mt-3"
          block
          v-if="!$store.state.roomservice.canceled"
          color="red"
          :loading="
            loading || $store.state.roomservice.messageStatus === 'fetching'
          "
          dark
          @click="cancelCleanup()"
          >{{ $t("roomservice.confirm") }}</v-btn
        >
        <v-btn text class="mt-3" v-else color="green" dark>
          <v-icon class="mr-2">mdi-check</v-icon>
          {{ $t("roomservice.confirmed") }}
        </v-btn>
      </v-card-text>
    </v-card>
    <v-card class="mb-5" outlined>
      <v-row>
        <v-col cols="9" sm="10" md="11">
          <v-card-title style="word-break: break-word">
            {{ $t("roomservice.scheduleTitle") }}
          </v-card-title>
          <!-- style="word-break: break-word" necessary for smaller screens! -->
          <v-card-text>{{ $t("roomservice.scheduleText") }}</v-card-text>
        </v-col>
        <v-col align-self="center" cols="3" sm="2" md="1">
          <v-switch
            color="primary"
            :input-value="$store.state.roomservice.showSchedule"
            @change="$store.commit('roomservice/TOGGLE_SCHEDULE')"
          ></v-switch>
        </v-col>
      </v-row>
    </v-card>
    <v-container
      justify="space-around"
      class="mb-5"
      v-if="$store.state.roomservice.showSchedule"
    >
      <v-row no-gutters>
        <v-col
          class="d-flex justify-center"
          v-for="(item, index) in buttons"
          :key="index"
        >
          <toggle-button
            :weekday="item.weekday"
            :button="item.state"
          ></toggle-button>
        </v-col>
      </v-row>
    </v-container>
    <scheduler :cardTitle="$t('roomservice.makeupRoom')"></scheduler>
    <scheduler :cardTitle="$t('roomservice.collectLaundry')"></scheduler>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Scheduler from "@/components/Scheduler";
import ToggleButton from "@/components/ToggleButton";

export default {
  data() {
    return {
      weekdays: [],
      loading: false,
    };
  },
  components: {
    Scheduler,
    ToggleButton,
  },
  props: {
    Header: String,
    Description: String,
    MailContent: String,
  },
  methods: {
    ...mapActions("roomservice", ["postConfirmationmail"]),
    toggleWeekdays() {
      this.$store.commit("roomservice/MUTATE_WEEKDAYS", this.weekdays);
    },
    cancelCleanup() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.postConfirmationmail({
          sender: "ONEapp",
          subject: "Cancel Cleanup Confirmation",
          content: this.MailContent,
        });
      }, 300);
    },
  },
  computed: {
    buttons() {
      return [
        {
          weekday: "monday",
          state: false,
        },
        {
          weekday: "tuesday",
          state: false,
        },
        {
          weekday: "wednesday",
          state: false,
        },
        {
          weekday: "thursday",
          state: false,
        },
        {
          weekday: "friday",
          state: false,
        },
        {
          weekday: "saturday",
          state: false,
        },
        {
          weekday: "sunday",
          state: false,
        },
      ];
    },
  },
};
</script>
