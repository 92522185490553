<template>
  <v-container class="pa-0 ma-0" grid-list-lg>
    <v-layout class="pa-0 ma-0" row wrap>
      <template v-for="(item, index) in list">
        <v-flex :key="index" xs12 lg6>
          <base-contentpage-card :item="item" />
        </v-flex>
        <v-flex
          class="px-0"
          v-if="$vuetify.breakpoint.mdAndDown"
          :key="'seperator-' + index"
          xs12
        >
          <base-seperator />
        </v-flex>
      </template>
    </v-layout>
  </v-container>
</template>

<script>
import BaseContentpageCard from "@/components/contentpage/base/BaseContentpageCard";
import BaseSeperator from "@/components/contentpage/base/BaseSeperator";

export default {
  components: {
    BaseContentpageCard,
    BaseSeperator,
  },
  props: {
    list: Array,
  },
};
</script>
