<template>
  <v-card outlined v-if="data && data.observation">
    <v-list two-line>
      <v-list-item>
        <v-avatar tile size="60">
          <v-row row wrap no-gutters>
            <v-col cols="12">
              <v-img max-height="34" max-width="34" contain :src="img" />
            </v-col>
            <v-col cols="12" class="text-subtitle-2">{{
              (isFahrenheit
                ? data.observation.temp_f
                : data.observation.temp_c) + "°"
            }}</v-col>
          </v-row>
        </v-avatar>
        <v-divider vertical class="px-2"></v-divider>
        <v-list-item-content class="pl-3">
          <v-list-item-title class="text-subtitle-1">
            {{ data.city }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <span
              v-html="
                data.observation.i18nCondition || data.observation.weather
              "
            ></span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn :loading="status === 'fetching'" icon @click="loadWeatherData">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "WeatherWidget",
  created() {
    if (!this.data.observation) {
      this.loadWeatherData();
    }
  },
  computed: {
    ...mapState({
      data: ({ weather }) => weather.data,
      status: ({ weather }) => weather.status,
      refreshDate: ({ weather }) => weather.date,
    }),
    isFahrenheit() {
      return (
        this.$config.weatherUnit === "FC" || this.$config.weatherUnit === "F"
      );
    },
    img() {
      try {
        return require("./../assets/icons/weather/" +
          this.data.observation.weather
            .toLowerCase()
            .split("/")[0]
            .split(" ")
            .join("") +
          ".png");
      } catch (e) {
        return require("./../assets/icons/weather/na.png");
      }
    },
  },
  methods: {
    ...mapActions("weather", ["loadWeatherData"]),
  },
};
</script>
