<template>
  <v-container
    class="pa-0"
    v-bind="{ [`grid-list-${$vuetify.breakpoint.name}`]: true }"
  >
    <v-layout row wrap>
      <v-flex
        v-if="
          (item.Header && item.Header.length > 0) ||
          (item.Image && item.Image.length > 0)
        "
        v-for="(item, index) in list"
        :key="index"
        xs6
        md4
        xl2
      >
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card
              flat
              tile
              @click="
                (dialog = true), (dialogImg = $config.managerMedia + item.Image)
              "
            >
              <v-img
                :src="$config.managerMedia + item.Image"
                :lazy-src="$config.managerMedia + item.Image"
                aspect-ratio="1"
              >
                <v-layout
                  slot="placeholder"
                  fill-height
                  align-center
                  justify-center
                  ma-0
                >
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-layout>
                <v-overlay
                  v-if="$vuetify.breakpoint.xsOnly"
                  absolute
                  color="#000000"
                  opacity="0.1"
                >
                  <v-icon large style="opacity: 0.5"
                    >mdi-magnify-plus-outline</v-icon
                  >
                </v-overlay>
              </v-img>
              <v-card-title
                style="word-break: break-word"
                v-if="item.Header && item.Header.length > 0"
                primary-title
              >
                <h3 class="text-subtitle-1 mb-0" v-html="item.Header"></h3>
              </v-card-title>
              <v-fade-transition>
                <v-overlay v-if="hover && $vuetify.breakpoint.smAndUp" absolute>
                  <v-icon large>mdi-magnify-plus-outline</v-icon>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" fullscreen>
      <v-card
        flat
        tile
        :style="{
          backgroundImage: 'url(' + dialogImg + ')',
          backgroundSize: 'contain',
          backgroundPosition: 'center center',
        }"
      >
        <v-card-actions>
          <v-btn fab small fixed top right @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  props: {
    list: Array,
  },
  data() {
    return {
      dialog: false,
      dialogImg: undefined,
    };
  },
};
</script>
