<template>
  <v-container
    class="align-start pa-0"
    :style="`height: 100%; position: relative;`"
  >
    <v-toolbar
      flat
      style="
        z-index: 1;
        positon: absolute;
        background-color: transparent;
        position: absolute;
      "
    >
      <v-tabs show-arrows dark color="primary" v-model="tab">
        <v-tab :key="item.Title + index" v-for="(item, index) in list">
          {{ item.Title }}
        </v-tab>
      </v-tabs>
    </v-toolbar>

    <v-container
      fluid
      :style="`
    height: 100%;
    background-position: center;
    background-size: cover;
    background-image: url(${$config.managerMedia + list[tab].Background});
    position: absolute;`"
      class="pa-0 ma-0"
      grid-list-lg
    >
      <template>
        <v-row
          wrap
          class="fill-height"
          height="100%"
          align="end"
          justify="center"
        >
          <v-card
            style="margin-bottom: 120px"
            :to="to"
            flat
            color="transparent"
          >
            <v-row align="end" justify="center">
              <v-col class="pa-0" cols="12">
                <v-row no-gutters align="end" justify="center">
                  <v-icon large color="primary">mdi-chevron-up</v-icon>
                </v-row>
              </v-col>
              <v-col class="pa-0" cols="12">
                <v-row align="end" justify="center">
                  <v-btn :to="to" :ripple="false" text color="primary">
                    {{ list[tab].ActionTitle }}
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
      </template>
    </v-container>
  </v-container>
</template>

<script>
import BaseContentpageCard from "@/components/contentpage/base/BaseContentpageCard";
import BaseSeperator from "@/components/contentpage/base/BaseSeperator";
import { getRoute } from "@/util/contentpage";

export default {
  data: () => ({
    tab: 0,
  }),
  components: {
    BaseContentpageCard,
    BaseSeperator,
  },
  props: {
    list: Array,
  },
  computed: {
    to() {
      return getRoute({
        onclick: this.list[this.tab].Action,
        page: this.list[this.tab].Page,
      });
    },
  },
};
</script>
