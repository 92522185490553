<template>
  <v-card class="mb-5" outlined v-if="$store.state.roomservice.showSchedule">
    <v-card-title style="word-break: break-word" primary-title>{{
      cardTitle
    }}</v-card-title>
    <v-card-text>
      <div style="white-space: pre-wrap"></div>
      <v-menu
        ref="fromMenu"
        v-model="fromMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="start"
        transition="slide-y-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            clearable
            v-model="start"
            :label="$t('roomservice.from')"
            prepend-icon="mdi-timetable"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="fromMenu"
          :format="format"
          v-model="start"
          :max="end"
          @click:minute="$refs.fromMenu.save(start)"
          ><div class="flex-grow-1"></div>
          <v-btn-toggle v-model="format">
            <v-btn text value="24hr">
              {{ $t("roomservice.ampm") }}
            </v-btn>
          </v-btn-toggle></v-time-picker
        >
      </v-menu>
      <v-menu
        ref="toMenu"
        v-model="toMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="end"
        transition="slide-y-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            clearable
            v-model="end"
            :label="$t('roomservice.to')"
            prepend-icon="mdi-timetable"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker
          v-if="toMenu"
          :format="format"
          v-model="end"
          :min="start"
          @click:minute="$refs.toMenu.save(end)"
          ><div class="flex-grow-1"></div>
          <v-btn-toggle v-model="format">
            <v-btn text value="24hr">
              {{ $t("roomservice.ampm") }}
            </v-btn>
          </v-btn-toggle></v-time-picker
        >
      </v-menu>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      fromMenu: false,
      toMenu: false,
      start: null,
      end: null,
      format: "ampm",
    };
  },
  props: ["cardTitle"],
};
</script>
