import mapboxgl from "mapbox-gl";

window.Done = (function (e) {
  "use strict";
  function t(e) {
    return (t =
      "function" == typeof Symbol && "symbol" == typeof Symbol.iterator
        ? function (e) {
            return typeof e;
          }
        : function (e) {
            return e &&
              "function" == typeof Symbol &&
              e.constructor === Symbol &&
              e !== Symbol.prototype
              ? "symbol"
              : typeof e;
          })(e);
  }
  function n(e, t) {
    if (!(e instanceof t))
      throw new TypeError("Cannot call a class as a function");
  }
  function r(e, t) {
    for (var n = 0; n < t.length; n++) {
      var r = t[n];
      (r.enumerable = r.enumerable || !1),
        (r.configurable = !0),
        "value" in r && (r.writable = !0),
        Object.defineProperty(e, r.key, r);
    }
  }
  function i(e, t, n) {
    return t && r(e.prototype, t), n && r(e, n), e;
  }
  function o(e, t, n) {
    return (
      t in e
        ? Object.defineProperty(e, t, {
            value: n,
            enumerable: !0,
            configurable: !0,
            writable: !0,
          })
        : (e[t] = n),
      e
    );
  }
  function c(e, t) {
    var n = Object.keys(e);
    if (Object.getOwnPropertySymbols) {
      var r = Object.getOwnPropertySymbols(e);
      t &&
        (r = r.filter(function (t) {
          return Object.getOwnPropertyDescriptor(e, t).enumerable;
        })),
        n.push.apply(n, r);
    }
    return n;
  }
  function a(e) {
    for (var t = 1; t < arguments.length; t++) {
      var n = null != arguments[t] ? arguments[t] : {};
      t % 2
        ? c(n, !0).forEach(function (t) {
            o(e, t, n[t]);
          })
        : Object.getOwnPropertyDescriptors
        ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(n))
        : c(n).forEach(function (t) {
            Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(n, t));
          });
    }
    return e;
  }
  function s(e, t) {
    if ("function" != typeof t && null !== t)
      throw new TypeError("Super expression must either be null or a function");
    (e.prototype = Object.create(t && t.prototype, {
      constructor: { value: e, writable: !0, configurable: !0 },
    })),
      t && l(e, t);
  }
  function u(e) {
    return (u = Object.setPrototypeOf
      ? Object.getPrototypeOf
      : function (e) {
          return e.__proto__ || Object.getPrototypeOf(e);
        })(e);
  }
  function l(e, t) {
    return (l =
      Object.setPrototypeOf ||
      function (e, t) {
        return (e.__proto__ = t), e;
      })(e, t);
  }
  function h(e) {
    if (undefined === e)
      throw new ReferenceError(
        "this hasn't been initialised - super() hasn't been called"
      );
    return e;
  }
  function f(e, t) {
    return !t || ("object" != typeof t && "function" != typeof t) ? h(e) : t;
  }
  function p(e, t) {
    return (
      (function (e) {
        if (Array.isArray(e)) return e;
      })(e) ||
      (function (e, t) {
        var n = [],
          r = !0,
          i = !1,
          o = undefined;
        try {
          for (
            var c, a = e[Symbol.iterator]();
            !(r = (c = a.next()).done) &&
            (n.push(c.value), !t || n.length !== t);
            r = !0
          );
        } catch (e) {
          (i = !0), (o = e);
        } finally {
          try {
            r || null == a.return || a.return();
          } finally {
            if (i) throw o;
          }
        }
        return n;
      })(e, t) ||
      (function () {
        throw new TypeError(
          "Invalid attempt to destructure non-iterable instance"
        );
      })()
    );
  }
  var v, y;
  ((v = e.PinType || (e.PinType = {}))[(v.Default = 0)] = "Default"),
    (v[(v.Hotel = 1)] = "Hotel"),
    (v[(v.Event = 2)] = "Event"),
    (v[(v.Gastro = 3)] = "Gastro"),
    (v[(v.Tour = 4)] = "Tour"),
    (v[(v.POI = 5)] = "POI"),
    (v[(v.City = 6)] = "City"),
    (v[(v.Area = 7)] = "Area"),
    (v[(v.Package = 8)] = "Package"),
    (v[(v.Article = 9)] = "Article"),
    (function (e) {
      (e[(e.GL = 0)] = "GL"), (e[(e.TileJSON = 1)] = "TileJSON");
    })(y || (y = {}));
  var d = (function () {
    function e(t) {
      n(this, e),
        o(this, "id", undefined),
        o(this, "title", undefined),
        o(this, "baseUrl", undefined),
        (this.id = t.id),
        (this.title = t.title || t.id),
        (this.baseUrl = e.DEFAULT_URL);
    }
    return (
      i(e, [
        {
          key: "getUrl",
          value: function (e) {
            switch (e) {
              case y.GL:
                return ""
                  .concat(this.baseUrl, "/styles/")
                  .concat(this.id, "/style.json");
              case y.TileJSON:
                return ""
                  .concat(this.baseUrl, "/styles/")
                  .concat(this.id, ".json");
            }
          },
        },
      ]),
      e
    );
  })();
  o(d, "DEFAULT_URL", "https://omt.ecmaps.de");
  var m = (function () {
    function e(t) {
      n(this, e),
        o(this, "id", undefined),
        o(this, "title", undefined),
        o(this, "mapStyle", undefined),
        (this.mapStyle = t),
        (this.id = t.id),
        (this.title = t.title || t.id);
    }
    return (
      i(e, [
        {
          key: "isValid",
          value: function () {
            return (
              undefined !== this.mapStyle.vectorUrl &&
              "" !== this.mapStyle.vectorUrl
            );
          },
        },
        {
          key: "getUrl",
          value: function (e) {
            switch (e) {
              case y.GL:
                return this.mapStyle.vectorUrl;
              case y.TileJSON:
                return ""
                  .concat(d.DEFAULT_URL, "/styles/")
                  .concat(this.id, ".json");
            }
          },
        },
      ]),
      e
    );
  })();
  Function.prototype.$asyncbind = function e(n, r) {
    function i() {
      return o.apply(n, arguments);
    }
    Function.prototype.$asyncbind ||
      Object.defineProperty(Function.prototype, "$asyncbind", {
        value: e,
        enumerable: !1,
        configurable: !0,
        writable: !0,
      }),
      e.trampoline ||
        (e.trampoline = function (e, t, n, r, i) {
          return function o(c) {
            for (; c; ) {
              if (c.then) return (c = c.then(o, r)), i ? undefined : c;
              try {
                if (c.pop) {
                  if (c.length) return c.pop() ? t.call(e) : c;
                  c = n;
                } else c = c.call(e);
              } catch (e) {
                return r(e);
              }
            }
          };
        }),
      e.LazyThenable ||
        ((e.LazyThenable = (function () {
          function e(e) {
            return e && e instanceof Object && "function" == typeof e.then;
          }
          function t(n, r, i) {
            try {
              var o = i ? i(r) : r;
              if (n === o)
                return n.reject(new TypeError("Promise resolution loop"));
              e(o)
                ? o.then(
                    function (e) {
                      t(n, e);
                    },
                    function (e) {
                      n.reject(e);
                    }
                  )
                : n.resolve(o);
            } catch (e) {
              n.reject(e);
            }
          }
          function n(e) {}
          function r() {}
          function i(n, i) {
            var o = new r();
            try {
              this._resolver(
                function (r) {
                  return e(r) ? r.then(n, i) : t(o, r, n);
                },
                function (e) {
                  t(o, e, i);
                }
              );
            } catch (e) {
              t(o, e, i);
            }
            return o;
          }
          function o(e) {
            (this._resolver = e), (this.then = i);
          }
          return (
            (r.prototype = {
              resolve: n,
              reject: n,
              then: function (e, t) {
                (this.resolve = e), (this.reject = t);
              },
            }),
            (o.resolve = function (e) {
              return o.isThenable(e)
                ? e
                : {
                    then: function (t) {
                      return t(e);
                    },
                  };
            }),
            (o.isThenable = e),
            o
          );
        })()),
        (e.EagerThenable = e.Thenable = (e.EagerThenableFactory = function (e) {
          e =
            e ||
            ("object" ===
              ("undefined" == typeof process ? "undefined" : t(process)) &&
              process.nextTick) ||
            ("function" == typeof setImmediate && setImmediate) ||
            function (e) {
              setTimeout(e, 0);
            };
          var n = (function () {
            var t = [],
              n = 0,
              r = 1024;
            function i() {
              for (; t.length - n; ) {
                try {
                  t[n]();
                } catch (e) {}
                (t[n++] = undefined), n === r && (t.splice(0, r), (n = 0));
              }
            }
            return function (r) {
              t.push(r), t.length - n == 1 && e(i);
            };
          })();
          function r(e) {
            if (e) {
              var t = this;
              e(
                function (e) {
                  t.resolve(e);
                },
                function (e) {
                  t.reject(e);
                }
              );
            }
          }
          function i(e, t) {
            if ("function" == typeof e.y)
              try {
                var n = e.y.call(undefined, t);
                e.p.resolve(n);
              } catch (t) {
                e.p.reject(t);
              }
            else e.p.resolve(t);
          }
          function o(e, t) {
            if ("function" == typeof e.n)
              try {
                var n = e.n.call(undefined, t);
                e.p.resolve(n);
              } catch (t) {
                e.p.reject(t);
              }
            else e.p.reject(t);
          }
          return (
            (r.prototype = {
              resolve: function (e) {
                if (undefined === this.state) {
                  if (e === this)
                    return this.reject(
                      new TypeError("Attempt to resolve promise with self")
                    );
                  var r = this;
                  if (e && ("function" == typeof e || "object" === t(e)))
                    try {
                      var o = 0,
                        c = e.then;
                      if ("function" == typeof c)
                        return void c.call(
                          e,
                          function (e) {
                            o++ || r.resolve(e);
                          },
                          function (e) {
                            o++ || r.reject(e);
                          }
                        );
                    } catch (e) {
                      return void (o || this.reject(e));
                    }
                  (this.state = i),
                    (this.v = e),
                    r.c &&
                      n(function () {
                        for (var t = 0, n = r.c.length; t < n; t++)
                          i(r.c[t], e);
                      });
                }
              },
              reject: function (e) {
                if (undefined === this.state) {
                  (this.state = o), (this.v = e);
                  var t = this.c;
                  t &&
                    n(function () {
                      for (var n = 0, r = t.length; n < r; n++) o(t[n], e);
                    });
                }
              },
              then: function (e, t) {
                var i = new r(),
                  o = { y: e, n: t, p: i };
                if (undefined === this.state)
                  this.c ? this.c.push(o) : (this.c = [o]);
                else {
                  var c = this.state,
                    a = this.v;
                  n(function () {
                    c(o, a);
                  });
                }
                return i;
              },
            }),
            (r.resolve = function (e) {
              if (e && e instanceof r) return e;
              var t = new r();
              return t.resolve(e), t;
            }),
            (r.reject = function (e) {
              if (e && e instanceof r) return e;
              var t = new r();
              return t.reject(e), t;
            }),
            (r.version = "2.3.3-nodent"),
            r
          );
        })()));
    var o = this;
    switch (r) {
      case !0:
        return new e.Thenable(i);
      case 0:
        return new e.LazyThenable(i);
      case undefined:
        return (i.then = i), i;
      default:
        return function () {
          try {
            return o.apply(n, arguments);
          } catch (e) {
            return r(e);
          }
        };
    }
  };
  var g = (function () {
    function e(t) {
      n(this, e),
        o(this, "defaultId", undefined),
        o(this, "styles", undefined),
        undefined !== t && t.length > 0
          ? (this.styles = t)
          : (this.styles = e.DEFAULT_STYLES),
        (this.defaultId = this.styles[0].id);
    }
    return (
      i(e, [
        {
          key: "getStyles",
          value: function () {
            return this.styles;
          },
        },
        {
          key: "getMapStyle",
          value: function () {
            var e =
              arguments.length > 0 && undefined !== arguments[0]
                ? arguments[0]
                : this.defaultId;
            return (
              this.styles.find(function (t) {
                return t.id === e;
              }) || this.styles[0]
            );
          },
        },
        {
          key: "getMapStyleUrl",
          value: function (e) {
            var t =
              arguments.length > 1 && undefined !== arguments[1]
                ? arguments[1]
                : this.defaultId;
            return this.getMapStyle(t).getUrl(e);
          },
        },
        {
          key: "getMapStyleJSON",
          value: function (e) {
            var t = arguments;
            return new Promise(
              function (n, r) {
                var i =
                    t.length > 1 && undefined !== t[1] ? t[1] : this.defaultId,
                  o = this.getMapStyleUrl(e, i);
                return n(
                  o
                    ? fetch(o).then(function (e) {
                        if (!e.ok) throw new Error(e.statusText);
                        return e.json();
                      })
                    : undefined
                );
              }.$asyncbind(this)
            );
          },
        },
      ]),
      e
    );
  })();
  o(g, "DEFAULT_STYLES", [
    new d({ id: "osm-bright", title: "Sommer" }),
    new d({ id: "hybrid", title: "Luftbild" }),
  ]);
  var b = (function () {
    function e() {
      n(this, e);
    }
    return (
      i(e, null, [
        {
          key: "toMapStyles",
          value: function (e) {
            if (undefined !== e && e.mapStyles.length > 0) {
              var t = e.mapStyles.map(function (e) {
                return new m(e);
              });
              if (
                t.find(function (e) {
                  return e.isValid();
                })
              )
                return new g(t);
            }
            return new g();
          },
        },
        {
          key: "toCameraOptions",
          value: function (e) {
            if (undefined !== e) {
              var t = e.defaultMapView;
              return {
                center: { lat: t.latitude, lng: t.longitude },
                zoom: t.zoom,
              };
            }
            return {};
          },
        },
      ]),
      e
    );
  })();
  Function.prototype.$asyncbind = function e(n, r) {
    function i() {
      return o.apply(n, arguments);
    }
    Function.prototype.$asyncbind ||
      Object.defineProperty(Function.prototype, "$asyncbind", {
        value: e,
        enumerable: !1,
        configurable: !0,
        writable: !0,
      }),
      e.trampoline ||
        (e.trampoline = function (e, t, n, r, i) {
          return function o(c) {
            for (; c; ) {
              if (c.then) return (c = c.then(o, r)), i ? undefined : c;
              try {
                if (c.pop) {
                  if (c.length) return c.pop() ? t.call(e) : c;
                  c = n;
                } else c = c.call(e);
              } catch (e) {
                return r(e);
              }
            }
          };
        }),
      e.LazyThenable ||
        ((e.LazyThenable = (function () {
          function e(e) {
            return e && e instanceof Object && "function" == typeof e.then;
          }
          function t(n, r, i) {
            try {
              var o = i ? i(r) : r;
              if (n === o)
                return n.reject(new TypeError("Promise resolution loop"));
              e(o)
                ? o.then(
                    function (e) {
                      t(n, e);
                    },
                    function (e) {
                      n.reject(e);
                    }
                  )
                : n.resolve(o);
            } catch (e) {
              n.reject(e);
            }
          }
          function n(e) {}
          function r() {}
          function i(n, i) {
            var o = new r();
            try {
              this._resolver(
                function (r) {
                  return e(r) ? r.then(n, i) : t(o, r, n);
                },
                function (e) {
                  t(o, e, i);
                }
              );
            } catch (e) {
              t(o, e, i);
            }
            return o;
          }
          function o(e) {
            (this._resolver = e), (this.then = i);
          }
          return (
            (r.prototype = {
              resolve: n,
              reject: n,
              then: function (e, t) {
                (this.resolve = e), (this.reject = t);
              },
            }),
            (o.resolve = function (e) {
              return o.isThenable(e)
                ? e
                : {
                    then: function (t) {
                      return t(e);
                    },
                  };
            }),
            (o.isThenable = e),
            o
          );
        })()),
        (e.EagerThenable = e.Thenable = (e.EagerThenableFactory = function (e) {
          e =
            e ||
            ("object" ===
              ("undefined" == typeof process ? "undefined" : t(process)) &&
              process.nextTick) ||
            ("function" == typeof setImmediate && setImmediate) ||
            function (e) {
              setTimeout(e, 0);
            };
          var n = (function () {
            var t = [],
              n = 0,
              r = 1024;
            function i() {
              for (; t.length - n; ) {
                try {
                  t[n]();
                } catch (e) {}
                (t[n++] = undefined), n === r && (t.splice(0, r), (n = 0));
              }
            }
            return function (r) {
              t.push(r), t.length - n == 1 && e(i);
            };
          })();
          function r(e) {
            if (e) {
              var t = this;
              e(
                function (e) {
                  t.resolve(e);
                },
                function (e) {
                  t.reject(e);
                }
              );
            }
          }
          function i(e, t) {
            if ("function" == typeof e.y)
              try {
                var n = e.y.call(undefined, t);
                e.p.resolve(n);
              } catch (t) {
                e.p.reject(t);
              }
            else e.p.resolve(t);
          }
          function o(e, t) {
            if ("function" == typeof e.n)
              try {
                var n = e.n.call(undefined, t);
                e.p.resolve(n);
              } catch (t) {
                e.p.reject(t);
              }
            else e.p.reject(t);
          }
          return (
            (r.prototype = {
              resolve: function (e) {
                if (undefined === this.state) {
                  if (e === this)
                    return this.reject(
                      new TypeError("Attempt to resolve promise with self")
                    );
                  var r = this;
                  if (e && ("function" == typeof e || "object" === t(e)))
                    try {
                      var o = 0,
                        c = e.then;
                      if ("function" == typeof c)
                        return void c.call(
                          e,
                          function (e) {
                            o++ || r.resolve(e);
                          },
                          function (e) {
                            o++ || r.reject(e);
                          }
                        );
                    } catch (e) {
                      return void (o || this.reject(e));
                    }
                  (this.state = i),
                    (this.v = e),
                    r.c &&
                      n(function () {
                        for (var t = 0, n = r.c.length; t < n; t++)
                          i(r.c[t], e);
                      });
                }
              },
              reject: function (e) {
                if (undefined === this.state) {
                  (this.state = o), (this.v = e);
                  var t = this.c;
                  t &&
                    n(function () {
                      for (var n = 0, r = t.length; n < r; n++) o(t[n], e);
                    });
                }
              },
              then: function (e, t) {
                var i = new r(),
                  o = { y: e, n: t, p: i };
                if (undefined === this.state)
                  this.c ? this.c.push(o) : (this.c = [o]);
                else {
                  var c = this.state,
                    a = this.v;
                  n(function () {
                    c(o, a);
                  });
                }
                return i;
              },
            }),
            (r.resolve = function (e) {
              if (e && e instanceof r) return e;
              var t = new r();
              return t.resolve(e), t;
            }),
            (r.reject = function (e) {
              if (e && e instanceof r) return e;
              var t = new r();
              return t.reject(e), t;
            }),
            (r.version = "2.3.3-nodent"),
            r
          );
        })()));
    var o = this;
    switch (r) {
      case !0:
        return new e.Thenable(i);
      case 0:
        return new e.LazyThenable(i);
      case undefined:
        return (i.then = i), i;
      default:
        return function () {
          try {
            return o.apply(n, arguments);
          } catch (e) {
            return r(e);
          }
        };
    }
  };
  var w = (function () {
    function e() {
      n(this, e);
    }
    return (
      i(e, null, [
        {
          key: "createServiceUrl",
          value: function (e) {
            return "https://meta.et4.de/rest.ashx/" + e + "/?";
          },
        },
        {
          key: "createUrlParameter",
          value: function (e) {
            return Object.entries(e)
              .map(function (e) {
                var t = p(e, 2),
                  n = t[0],
                  r = t[1];
                if ("parameters" !== n && r)
                  return encodeURIComponent(n) + "=" + encodeURIComponent(r);
              })
              .join("&");
          },
        },
        {
          key: "prepareMetaSearch",
          value: function (e, t, n) {
            var r = "search";
            return (
              e.sort &&
                ("bookablefirst" === e.sort || e.sort.startsWith("price")) &&
                ((r = "hypersearch"),
                e.template &&
                  (e.template = e.template
                    .replace("_LIGHT.", ".")
                    .replace("_L.", "."))),
              {
                filters: t,
                method: "get",
                request: e,
                requestInit: n,
                service: r,
              }
            );
          },
        },
        {
          key: "preparePredefinedCall",
          value: function (e, t) {
            return {
              method: "get",
              request: { experience: e, format: t },
              service: "config/predefined",
            };
          },
        },
        {
          key: "fetch",
          value: (function (e) {
            function t(t) {
              return e.apply(this, arguments);
            }
            return (
              (t.toString = function () {
                return e.toString();
              }),
              t
            );
          })(function (e) {
            return new Promise(
              function (t, n) {
                var r = this.createServiceUrl(e.service),
                  i = e.request,
                  o = e.filters,
                  c = e.requestInit || {},
                  s = i.template;
                return (
                  "post" === e.method
                    ? ((c.method = "post"),
                      (c.body = JSON.stringify(a({}, i, { parameters: [i] }))))
                    : (r += this.createUrlParameter(i)),
                  o &&
                    o.geometryOnly &&
                    undefined !== s &&
                    s.endsWith(".json") &&
                    (r += "&json-filter=items[global_id,geo[geometry[value]]]"),
                  t(fetch(r, c))
                );
              }.$asyncbind(this)
            );
          }),
        },
        {
          key: "fetchJSON",
          value: function (e) {
            return new Promise(
              function (t, n) {
                return t(
                  this.fetch(e).then(function (e) {
                    if (!e.ok) throw new Error(e.statusText);
                    return e.json();
                  })
                );
              }.$asyncbind(this)
            );
          },
        },
        {
          key: "fetchUint8Array",
          value: function (e) {
            return new Promise(
              function (t, n) {
                return t(
                  this.fetch(e)
                    .then(function (e) {
                      if (!e.ok) throw new Error(e.statusText);
                      return e.arrayBuffer();
                    })
                    .then(function (e) {
                      return new Uint8Array(e);
                    })
                );
              }.$asyncbind(this)
            );
          },
        },
      ]),
      e
    );
  })();
  Function.prototype.$asyncbind = function e(n, r) {
    function i() {
      return o.apply(n, arguments);
    }
    Function.prototype.$asyncbind ||
      Object.defineProperty(Function.prototype, "$asyncbind", {
        value: e,
        enumerable: !1,
        configurable: !0,
        writable: !0,
      }),
      e.trampoline ||
        (e.trampoline = function (e, t, n, r, i) {
          return function o(c) {
            for (; c; ) {
              if (c.then) return (c = c.then(o, r)), i ? undefined : c;
              try {
                if (c.pop) {
                  if (c.length) return c.pop() ? t.call(e) : c;
                  c = n;
                } else c = c.call(e);
              } catch (e) {
                return r(e);
              }
            }
          };
        }),
      e.LazyThenable ||
        ((e.LazyThenable = (function () {
          function e(e) {
            return e && e instanceof Object && "function" == typeof e.then;
          }
          function t(n, r, i) {
            try {
              var o = i ? i(r) : r;
              if (n === o)
                return n.reject(new TypeError("Promise resolution loop"));
              e(o)
                ? o.then(
                    function (e) {
                      t(n, e);
                    },
                    function (e) {
                      n.reject(e);
                    }
                  )
                : n.resolve(o);
            } catch (e) {
              n.reject(e);
            }
          }
          function n(e) {}
          function r() {}
          function i(n, i) {
            var o = new r();
            try {
              this._resolver(
                function (r) {
                  return e(r) ? r.then(n, i) : t(o, r, n);
                },
                function (e) {
                  t(o, e, i);
                }
              );
            } catch (e) {
              t(o, e, i);
            }
            return o;
          }
          function o(e) {
            (this._resolver = e), (this.then = i);
          }
          return (
            (r.prototype = {
              resolve: n,
              reject: n,
              then: function (e, t) {
                (this.resolve = e), (this.reject = t);
              },
            }),
            (o.resolve = function (e) {
              return o.isThenable(e)
                ? e
                : {
                    then: function (t) {
                      return t(e);
                    },
                  };
            }),
            (o.isThenable = e),
            o
          );
        })()),
        (e.EagerThenable = e.Thenable = (e.EagerThenableFactory = function (e) {
          e =
            e ||
            ("object" ===
              ("undefined" == typeof process ? "undefined" : t(process)) &&
              process.nextTick) ||
            ("function" == typeof setImmediate && setImmediate) ||
            function (e) {
              setTimeout(e, 0);
            };
          var n = (function () {
            var t = [],
              n = 0,
              r = 1024;
            function i() {
              for (; t.length - n; ) {
                try {
                  t[n]();
                } catch (e) {}
                (t[n++] = undefined), n === r && (t.splice(0, r), (n = 0));
              }
            }
            return function (r) {
              t.push(r), t.length - n == 1 && e(i);
            };
          })();
          function r(e) {
            if (e) {
              var t = this;
              e(
                function (e) {
                  t.resolve(e);
                },
                function (e) {
                  t.reject(e);
                }
              );
            }
          }
          function i(e, t) {
            if ("function" == typeof e.y)
              try {
                var n = e.y.call(undefined, t);
                e.p.resolve(n);
              } catch (t) {
                e.p.reject(t);
              }
            else e.p.resolve(t);
          }
          function o(e, t) {
            if ("function" == typeof e.n)
              try {
                var n = e.n.call(undefined, t);
                e.p.resolve(n);
              } catch (t) {
                e.p.reject(t);
              }
            else e.p.reject(t);
          }
          return (
            (r.prototype = {
              resolve: function (e) {
                if (undefined === this.state) {
                  if (e === this)
                    return this.reject(
                      new TypeError("Attempt to resolve promise with self")
                    );
                  var r = this;
                  if (e && ("function" == typeof e || "object" === t(e)))
                    try {
                      var o = 0,
                        c = e.then;
                      if ("function" == typeof c)
                        return void c.call(
                          e,
                          function (e) {
                            o++ || r.resolve(e);
                          },
                          function (e) {
                            o++ || r.reject(e);
                          }
                        );
                    } catch (e) {
                      return void (o || this.reject(e));
                    }
                  (this.state = i),
                    (this.v = e),
                    r.c &&
                      n(function () {
                        for (var t = 0, n = r.c.length; t < n; t++)
                          i(r.c[t], e);
                      });
                }
              },
              reject: function (e) {
                if (undefined === this.state) {
                  (this.state = o), (this.v = e);
                  var t = this.c;
                  t &&
                    n(function () {
                      for (var n = 0, r = t.length; n < r; n++) o(t[n], e);
                    });
                }
              },
              then: function (e, t) {
                var i = new r(),
                  o = { y: e, n: t, p: i };
                if (undefined === this.state)
                  this.c ? this.c.push(o) : (this.c = [o]);
                else {
                  var c = this.state,
                    a = this.v;
                  n(function () {
                    c(o, a);
                  });
                }
                return i;
              },
            }),
            (r.resolve = function (e) {
              if (e && e instanceof r) return e;
              var t = new r();
              return t.resolve(e), t;
            }),
            (r.reject = function (e) {
              if (e && e instanceof r) return e;
              var t = new r();
              return t.reject(e), t;
            }),
            (r.version = "2.3.3-nodent"),
            r
          );
        })()));
    var o = this;
    switch (r) {
      case !0:
        return new e.Thenable(i);
      case 0:
        return new e.LazyThenable(i);
      case undefined:
        return (i.then = i), i;
      default:
        return function () {
          try {
            return o.apply(n, arguments);
          } catch (e) {
            return r(e);
          }
        };
    }
  };
  var k = (function () {
      function e() {
        n(this, e);
      }
      return (
        i(e, null, [
          {
            key: "fetchConfig",
            value: function (e) {
              return new Promise(function (t, n) {
                return t(w.fetchJSON({ request: e, service: "config/maps" }));
              });
            },
          },
        ]),
        e
      );
    })(),
    T = (function () {
      function e() {
        n(this, e), o(this, "listeners", {});
      }
      return (
        i(e, [
          {
            key: "add",
            value: function (e, t) {
              var n = this.getListeners(e);
              n.includes(t) || n.push(t);
            },
          },
          {
            key: "remove",
            value: function (e, t) {
              var n = this.getListeners(e),
                r = n.indexOf(t);
              -1 !== r && n.splice(r, 1);
            },
          },
          {
            key: "fire",
            value: function (e, t) {
              this.getListeners(e).forEach(function (e) {
                return e(t);
              });
            },
          },
          {
            key: "getListeners",
            value: function (e) {
              var t = this.listeners[e];
              return t || ((t = []), (this.listeners[e] = t)), t;
            },
          },
        ]),
        e
      );
    })(),
    j = (function () {
      function e(t) {
        n(this, e),
          o(this, "adder", undefined),
          o(this, "clearer", undefined),
          o(this, "init", undefined),
          o(this, "type", undefined),
          (this.adder = t.adder),
          (this.clearer = t.clearer),
          (this.init = t.init),
          (this.type = t.type);
      }
      return (
        i(e, [
          {
            key: "createLayer",
            value: function (e, t) {
              var n = this.init(e, t);
              return this.adder(n), n;
            },
          },
          {
            key: "clearLayer",
            value: function (e, t) {
              var n = this.init(e, t);
              this.clearer(n);
            },
          },
        ]),
        e
      );
    })(),
    P = (function () {
      function e(t) {
        n(this, e),
          o(this, "id", undefined),
          o(this, "type", undefined),
          o(this, "persistent", undefined),
          o(this, "data", undefined),
          o(this, "layout", undefined),
          o(this, "paint", undefined),
          (this.id = t.id),
          (this.type = t.type),
          (this.persistent = t.persistent),
          (this.data = t.data),
          (this.layout = this.mergeSettings(this.getDefaultLayout(), t.layout)),
          (this.paint = this.mergeSettings(this.getDefaultPaint(), t.paint));
      }
      return (
        i(e, [
          {
            key: "getLayout",
            value: function (e) {
              return this.layout && e(this.layout);
            },
          },
          {
            key: "getLayoutOr",
            value: function (e, t) {
              return this.getLayout(e) || t;
            },
          },
          {
            key: "getPaint",
            value: function (e) {
              return this.paint && e(this.paint);
            },
          },
          {
            key: "getPaintOr",
            value: function (e, t) {
              return this.getPaint(e) || t;
            },
          },
          { key: "getDefaultLayout", value: function () {} },
          { key: "getDefaultPaint", value: function () {} },
          {
            key: "mergeSettings",
            value: function (e, t) {
              return e ? (t ? a({}, e, {}, t) : e) : t;
            },
          },
        ]),
        e
      );
    })(),
    M = (function (e) {
      function t(e) {
        var r;
        if (
          (n(this, t),
          ((r = f(this, u(t).call(this, e))).type = "line"),
          e.points)
        ) {
          if (e.data) throw new Error("You should define 'data' or 'line'!");
          r.setLine(e.points);
        }
        return r;
      }
      return (
        s(t, P),
        i(t, null, [
          {
            key: "init",
            value: function (e) {
              return e instanceof t ? e : new t(e);
            },
          },
        ]),
        i(t, [
          {
            key: "setLine",
            value: function (e) {
              this.data = {
                geometry: {
                  coordinates: e.map(function (e) {
                    return [e.lng, e.lat];
                  }),
                  type: "LineString",
                },
                properties: {},
                type: "Feature",
              };
            },
          },
          {
            key: "getDefaultLayout",
            value: function () {
              return t.DEFAULT_LAYOUT;
            },
          },
          {
            key: "getDefaultPaint",
            value: function () {
              return t.DEFAULT_PAINT;
            },
          },
        ]),
        t
      );
    })();
  o(M, "DEFAULT_LAYOUT", { arrows: !0, cap: "round" }),
    o(M, "DEFAULT_PAINT", { color: "#F7455D", width: 6 });
  var x = (function () {
      function e(t) {
        n(this, e),
          o(this, "lat", undefined),
          o(this, "lng", undefined),
          (this.lat = t.lat),
          (this.lng = t.lng);
      }
      return (
        i(e, null, [
          {
            key: "fromArray",
            value: function (t) {
              return new e({ lng: t[0], lat: t[1] });
            },
          },
          {
            key: "asArray",
            value: function (e) {
              return [e.lng, e.lat];
            },
          },
        ]),
        i(e, [
          {
            key: "toArray",
            value: function () {
              return e.asArray(this);
            },
          },
        ]),
        e
      );
    })(),
    E = (function () {
      function e(t, r) {
        n(this, e),
          o(this, "sw", undefined),
          o(this, "ne", undefined),
          (this.sw = new x(t)),
          (this.ne = new x(r));
      }
      return (
        i(e, null, [
          {
            key: "calculateBounds",
            value: function (t) {
              if (!(undefined === t || t.length < 1))
                return t.reduce(function (e, t) {
                  return e.extend(t);
                }, new e(t[0], t[0]));
            },
          },
        ]),
        i(e, [
          {
            key: "extend",
            value: function (e) {
              if (e) {
                var t = this.sw,
                  n = this.ne;
                t || n
                  ? ((t.lng = Math.min(e.lng, t.lng)),
                    (t.lat = Math.min(e.lat, t.lat)),
                    (n.lng = Math.max(e.lng, n.lng)),
                    (n.lat = Math.max(e.lat, n.lat)))
                  : ((this.sw = new x(e)), (this.ne = new x(e)));
              }
              return this;
            },
          },
          {
            key: "toArray",
            value: function () {
              return [this.sw.lng, this.sw.lat, this.ne.lng, this.ne.lat];
            },
          },
        ]),
        e
      );
    })(),
    S = (function () {
      function t() {
        n(this, t);
      }
      return (
        i(t, null, [
          {
            key: "textToPinType",
            value: function (t) {
              switch (t.toLocaleLowerCase()) {
                case "gastgeber":
                case "hotel":
                  return e.PinType.Hotel;
                case "events":
                case "event":
                  return e.PinType.Event;
                case "gastro":
                  return e.PinType.Gastro;
                case "touren":
                case "tour":
                  return e.PinType.Tour;
                case "poi":
                  return e.PinType.POI;
                case "city":
                  return e.PinType.City;
                case "area":
                  return e.PinType.Area;
                case "package":
                  return e.PinType.Package;
                case "article":
                  return e.PinType.Article;
                default:
                  return e.PinType.Default;
              }
            },
          },
          {
            key: "parsePolylineString",
            value: function (e) {
              try {
                for (
                  var t = e.split(" "), n = new Array(), r = 0;
                  r < t.length - 1;
                  r++
                )
                  n.push({ lat: Number(t[r]), lng: Number(t[++r]) });
                return n;
              } catch (e) {
                return [];
              }
            },
          },
        ]),
        t
      );
    })(),
    C = (function () {
      function e(t, r) {
        n(this, e),
          o(this, "x", undefined),
          o(this, "y", undefined),
          "number" == typeof t && "number" == typeof r
            ? ((this.x = t), (this.y = r))
            : ((this.x = t.x), (this.y = t.y));
      }
      return (
        i(e, null, [
          {
            key: "fromArray",
            value: function (t) {
              return new e(t[0], t[1]);
            },
          },
          {
            key: "asArray",
            value: function (e) {
              return [e.x, e.y];
            },
          },
        ]),
        i(e, [
          {
            key: "toArray",
            value: function () {
              return e.asArray(this);
            },
          },
          {
            key: "clone",
            value: function () {
              return new e(this);
            },
          },
          {
            key: "add",
            value: function (t) {
              return new e(this.x + t.x, this.y + t.y);
            },
          },
          {
            key: "sub",
            value: function (t) {
              return new e(this.x - t.x, this.y - t.y);
            },
          },
          {
            key: "mult",
            value: function (t) {
              return new e(this.x * t, this.y * t);
            },
          },
          {
            key: "div",
            value: function (t) {
              return new e(this.x / t, this.y / t);
            },
          },
        ]),
        e
      );
    })(),
    A = (function () {
      function e(t) {
        n(this, e),
          o(this, "id", undefined),
          o(this, "path", undefined),
          o(this, "size", undefined),
          o(this, "viewSize", undefined),
          o(this, "fill", undefined),
          o(this, "transform", undefined),
          (this.id = t.id),
          (this.path = t.path),
          (this.size = new C(t.size)),
          (this.viewSize = t.viewSize ? new C(t.viewSize) : new C(t.size)),
          (this.fill = t.fill || "white"),
          (this.transform = t.transform || "");
      }
      return (
        i(e, [
          {
            key: "create",
            value: function (t) {
              return new e(a({}, this, {}, t));
            },
          },
          {
            key: "renderSymbol",
            value: function () {
              return (
                '<symbol id="'
                  .concat(this.id, '" viewBox="0 0 ')
                  .concat(this.size.x, " ")
                  .concat(this.size.y, '">') +
                '<path d="'.concat(this.path, '"></path></symbol>')
              );
            },
          },
          {
            key: "renderUse",
            value: function () {
              return '<use xlink:href="#'
                .concat(this.id, '" fill="')
                .concat(this.fill, '" transform="')
                .concat(this.transform, '" />');
            },
          },
          {
            key: "renderSvg",
            value: function (e) {
              var t = "";
              return (
                undefined !== e &&
                  ("string" != typeof e
                    ? (t = e.renderSymbol() + e.renderUse())
                    : "" !== e && (t = e)),
                '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"' +
                  ' width="'
                    .concat(this.size.x, 'px" height="')
                    .concat(this.size.y, 'px" viewBox="0 0 ')
                    .concat(this.viewSize.x, " ")
                    .concat(this.viewSize.y, '">') +
                  ' <path d="'
                    .concat(this.path, '" fill="')
                    .concat(this.fill, '" transform="')
                    .concat(this.transform, '"></path>')
                    .concat(t) +
                  "</svg>"
              );
            },
          },
        ]),
        e
      );
    })(),
    z = "translate(12,12)scale(0.4)",
    I = function e() {
      n(this, e);
    };
  o(
    I,
    "Marker",
    new A({
      id: "sym-m",
      path:
        "M 21,1 C 9.954,1 1,10.10275 1,21.34375 1,26.407492 2.8058029,31.06341 5.8125,34.625 10.83933,39.999621 15.950478,45.546516 21,51 25.978317,45.576426 31.287399,39.938224 36.1875,34.625 39.194197,31.06341 41,26.407492 41,21.34375 41,10.10275 32.046,1 21,1 z",
      size: { x: 42, y: 52 },
    })
  ),
    o(
      I,
      "MarkerSmall",
      new A({
        id: "sym-m-s",
        path:
          "M 11,1 C 5.477,1 1,5.551375 1,11.171875 1,13.703746 1.9029015,16.031705 3.40625,17.8125 5.919665,20.49981 8.475239,23.273258 11,26 13.489159,23.288213 16.143699,20.469112 18.59375,17.8125 20.097099,16.031705 21,13.703746 21,11.171875 21,5.551375 16.523,1 11,1 z",
        size: { x: 22, y: 27 },
      })
    ),
    o(
      I,
      "Layers",
      new A({
        fill: "#000",
        id: "sym-lyr",
        path:
          "M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z",
        size: { x: 30, y: 30 },
        transform: "translate(64,64)scale(0.75)",
        viewSize: { x: 512, y: 512 },
      })
    ),
    o(
      I,
      "HotelSymbol",
      new A({
        id: "sym-ho",
        path:
          "M176 256c44.11 0 80-35.89 80-80s-35.89-80-80-80-80 35.89-80 80 35.89 80 80 80zm352-128H304c-8.84 0-16 7.16-16 16v144H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v352c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16v-48h512v48c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V240c0-61.86-50.14-112-112-112z",
        size: { x: 640, y: 512 },
        transform: z,
      })
    ),
    o(
      I,
      "EventSymbol",
      new A({
        id: "sym-ev",
        path:
          "M12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm436-44v-36c0-26.5-21.5-48-48-48h-48V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H160V12c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v36c0 6.6 5.4 12 12 12h424c6.6 0 12-5.4 12-12z",
        size: { x: 448, y: 512 },
        transform: z,
      })
    ),
    o(
      I,
      "GastroSymbol",
      new A({
        id: "sym-ga",
        path:
          "M207.9 15.2c.8 4.7 16.1 94.5 16.1 128.8 0 52.3-27.8 89.6-68.9 104.6L168 486.7c.7 13.7-10.2 25.3-24 25.3H80c-13.7 0-24.7-11.5-24-25.3l12.9-238.1C27.7 233.6 0 196.2 0 144 0 109.6 15.3 19.9 16.1 15.2 19.3-5.1 61.4-5.4 64 16.3v141.2c1.3 3.4 15.1 3.2 16 0 1.4-25.3 7.9-139.2 8-141.8 3.3-20.8 44.7-20.8 47.9 0 .2 2.7 6.6 116.5 8 141.8.9 3.2 14.8 3.4 16 0V16.3c2.6-21.6 44.8-21.4 48-1.1zm119.2 285.7l-15 185.1c-1.2 14 9.9 26 23.9 26h56c13.3 0 24-10.7 24-24V24c0-13.2-10.7-24-24-24-82.5 0-221.4 178.5-64.9 300.9z",
        size: { x: 416, y: 521 },
        transform: z,
      })
    ),
    o(
      I,
      "TourSymbol",
      new A({
        id: "sym-to",
        path:
          "M225.38 233.37c-12.5 12.5-12.5 32.76 0 45.25 12.49 12.5 32.76 12.5 45.25 0 12.5-12.5 12.5-32.76 0-45.25-12.5-12.49-32.76-12.49-45.25 0zM248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm126.14 148.05L308.17 300.4a31.938 31.938 0 0 1-15.77 15.77l-144.34 65.97c-16.65 7.61-33.81-9.55-26.2-26.2l65.98-144.35a31.938 31.938 0 0 1 15.77-15.77l144.34-65.97c16.65-7.6 33.8 9.55 26.19 26.2z",
        size: { x: 496, y: 521 },
        transform: z,
      })
    ),
    o(
      I,
      "PoiSymbol",
      new A({
        id: "sym-po",
        path:
          "M512 144v288c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48h88l12.3-32.9c7-18.7 24.9-31.1 44.9-31.1h125.5c20 0 37.9 12.4 44.9 31.1L376 96h88c26.5 0 48 21.5 48 48zM376 288c0-66.2-53.8-120-120-120s-120 53.8-120 120 53.8 120 120 120 120-53.8 120-120zm-32 0c0 48.5-39.5 88-88 88s-88-39.5-88-88 39.5-88 88-88 88 39.5 88 88z",
        size: { x: 512, y: 521 },
        transform: z,
      })
    ),
    o(
      I,
      "CitySymbol",
      new A({
        id: "sym-city",
        path:
          "M507.31 84.69L464 41.37c-6-6-14.14-9.37-22.63-9.37H288V16c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v16H56c-13.25 0-24 10.75-24 24v80c0 13.25 10.75 24 24 24h385.37c8.49 0 16.62-3.37 22.63-9.37l43.31-43.31c6.25-6.26 6.25-16.38 0-22.63zM224 496c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V384h-64v112zm232-272H288v-32h-64v32H70.63c-8.49 0-16.62 3.37-22.63 9.37L4.69 276.69c-6.25 6.25-6.25 16.38 0 22.63L48 342.63c6 6 14.14 9.37 22.63 9.37H456c13.25 0 24-10.75 24-24v-80c0-13.25-10.75-24-24-24z",
        size: { x: 512, y: 521 },
        transform: z,
      })
    ),
    o(
      I,
      "AreaSymbol",
      new A({
        id: "sym-area",
        path:
          "M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm200 248c0 22.5-3.9 44.2-10.8 64.4h-20.3c-4.3 0-8.4-1.7-11.4-4.8l-32-32.6c-4.5-4.6-4.5-12.1.1-16.7l12.5-12.5v-8.7c0-3-1.2-5.9-3.3-8l-9.4-9.4c-2.1-2.1-5-3.3-8-3.3h-16c-6.2 0-11.3-5.1-11.3-11.3 0-3 1.2-5.9 3.3-8l9.4-9.4c2.1-2.1 5-3.3 8-3.3h32c6.2 0 11.3-5.1 11.3-11.3v-9.4c0-6.2-5.1-11.3-11.3-11.3h-36.7c-8.8 0-16 7.2-16 16v4.5c0 6.9-4.4 13-10.9 15.2l-31.6 10.5c-3.3 1.1-5.5 4.1-5.5 7.6v2.2c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8s-3.6-8-8-8H247c-3 0-5.8 1.7-7.2 4.4l-9.4 18.7c-2.7 5.4-8.2 8.8-14.3 8.8H194c-8.8 0-16-7.2-16-16V199c0-4.2 1.7-8.3 4.7-11.3l20.1-20.1c4.6-4.6 7.2-10.9 7.2-17.5 0-3.4 2.2-6.5 5.5-7.6l40-13.3c1.7-.6 3.2-1.5 4.4-2.7l26.8-26.8c2.1-2.1 3.3-5 3.3-8 0-6.2-5.1-11.3-11.3-11.3H258l-16 16v8c0 4.4-3.6 8-8 8h-16c-4.4 0-8-3.6-8-8v-20c0-2.5 1.2-4.9 3.2-6.4l28.9-21.7c1.9-.1 3.8-.3 5.7-.3C358.3 56 448 145.7 448 256zM130.1 149.1c0-3 1.2-5.9 3.3-8l25.4-25.4c2.1-2.1 5-3.3 8-3.3 6.2 0 11.3 5.1 11.3 11.3v16c0 3-1.2 5.9-3.3 8l-9.4 9.4c-2.1 2.1-5 3.3-8 3.3h-16c-6.2 0-11.3-5.1-11.3-11.3zm128 306.4v-7.1c0-8.8-7.2-16-16-16h-20.2c-10.8 0-26.7-5.3-35.4-11.8l-22.2-16.7c-11.5-8.6-18.2-22.1-18.2-36.4v-23.9c0-16 8.4-30.8 22.1-39l42.9-25.7c7.1-4.2 15.2-6.5 23.4-6.5h31.2c10.9 0 21.4 3.9 29.6 10.9l43.2 37.1h18.3c8.5 0 16.6 3.4 22.6 9.4l17.3 17.3c3.4 3.4 8.1 5.3 12.9 5.3H423c-32.4 58.9-93.8 99.5-164.9 103.1z",
        size: { x: 496, y: 521 },
        transform: z,
      })
    ),
    o(
      I,
      "PackageSymbol",
      new A({
        id: "sym-pkg",
        path:
          "M32 448c0 17.7 14.3 32 32 32h160V320H32v128zm256 32h160c17.7 0 32-14.3 32-32V320H288v160zm192-320h-42.1c6.2-12.1 10.1-25.5 10.1-40 0-48.5-39.5-88-88-88-41.6 0-68.5 21.3-103 68.3-34.5-47-61.4-68.3-103-68.3-48.5 0-88 39.5-88 88 0 14.5 3.8 27.9 10.1 40H32c-17.7 0-32 14.3-32 32v80c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-80c0-17.7-14.3-32-32-32zm-326.1 0c-22.1 0-40-17.9-40-40s17.9-40 40-40c19.9 0 34.6 3.3 86.1 80h-86.1zm206.1 0h-86.1c51.4-76.5 65.7-80 86.1-80 22.1 0 40 17.9 40 40s-17.9 40-40 40z",
        size: { x: 512, y: 521 },
        transform: z,
      })
    ),
    o(
      I,
      "ArticleSymbol",
      new A({
        id: "sym-po",
        path:
          "M464 32H336c-26.5 0-48 21.5-48 48v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48zm-288 0H48C21.5 32 0 53.5 0 80v128c0 26.5 21.5 48 48 48h80v64c0 35.3-28.7 64-64 64h-8c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h8c88.4 0 160-71.6 160-160V80c0-26.5-21.5-48-48-48z",
        size: { x: 512, y: 521 },
        transform: z,
      })
    );
  var O = function e(t) {
      n(this, e),
        o(this, "color", undefined),
        o(this, "icon", undefined),
        o(this, "pinType", undefined),
        o(this, "symbol", undefined),
        (this.color = t.color),
        (this.icon = t.icon),
        (this.pinType = t.pinType),
        (this.symbol = t.symbol);
    },
    F = (function () {
      function e() {
        n(this, e);
      }
      return (
        i(e, null, [
          {
            key: "getMarkerType",
            value: function (t) {
              return (
                e.ALL.find(function (e) {
                  return e.pinType === t;
                }) || e.Default
              );
            },
          },
          {
            key: "getKey",
            value: function (e) {
              return "meta-" + e;
            },
          },
        ]),
        e
      );
    })();
  o(
    F,
    "Default",
    new O({ color: "#aaa", icon: "map-marker", pinType: e.PinType.Default })
  ),
    o(
      F,
      "Hotel",
      new O({
        color: "#f2a21d",
        icon: "bed",
        pinType: e.PinType.Hotel,
        symbol: I.HotelSymbol,
      })
    ),
    o(
      F,
      "Event",
      new O({
        color: "#bc85d0",
        icon: "calendar-o",
        pinType: e.PinType.Event,
        symbol: I.EventSymbol,
      })
    ),
    o(
      F,
      "Gastro",
      new O({
        color: "#d96243",
        icon: "cutlery",
        pinType: e.PinType.Gastro,
        symbol: I.GastroSymbol,
      })
    ),
    o(
      F,
      "Tour",
      new O({
        color: "#3da2d1",
        icon: "compass",
        pinType: e.PinType.Tour,
        symbol: I.TourSymbol,
      })
    ),
    o(
      F,
      "POI",
      new O({
        color: "#00ceb7",
        icon: "camera",
        pinType: e.PinType.POI,
        symbol: I.PoiSymbol,
      })
    ),
    o(
      F,
      "City",
      new O({
        color: "#aaa",
        icon: "map-signs",
        pinType: e.PinType.City,
        symbol: I.CitySymbol,
      })
    ),
    o(
      F,
      "Area",
      new O({
        color: "#aaa",
        icon: "globe",
        pinType: e.PinType.Area,
        symbol: I.AreaSymbol,
      })
    ),
    o(
      F,
      "Package",
      new O({
        color: "#536e84",
        icon: "gift",
        pinType: e.PinType.Package,
        symbol: I.PackageSymbol,
      })
    ),
    o(
      F,
      "Article",
      new O({
        color: "#87a239",
        icon: "quote-right",
        pinType: e.PinType.Article,
        symbol: I.ArticleSymbol,
      })
    ),
    o(F, "ALL", [
      F.Default,
      F.Hotel,
      F.Event,
      F.Gastro,
      F.Tour,
      F.POI,
      F.City,
      F.Area,
      F.Package,
      F.Article,
    ]);
  var D = (function () {
    function e(t) {
      n(this, e),
        o(this, "format", undefined),
        o(this, "mode", undefined),
        o(this, "scale", undefined),
        o(this, "width", undefined),
        o(this, "height", undefined),
        (this.format = t.format || "jpg"),
        (this.mode = t.mode || "crop"),
        (this.scale = t.scale || "both"),
        (this.width = t.width || 160),
        (this.height = t.height || 90);
    }
    return (
      i(e, [
        {
          key: "getUrl",
          value: function (t) {
            return (
              e.IMG_BASE_URL +
              "."
                .concat(this.format, "?mode=")
                .concat(this.mode, "&scale=")
                .concat(this.scale, "&width=")
                .concat(this.width, "&height=")
                .concat(this.height, "&url=") +
              encodeURIComponent(t)
            );
          },
        },
      ]),
      e
    );
  })();
  o(D, "DEFAULT", new D({})),
    o(D, "IMG_BASE_URL", "https://img.ecmaps.de/remote/");
  var _ = (function () {
      function e(t) {
        n(this, e),
          o(this, "anchor", "top"),
          o(this, "className", "done-popup"),
          o(this, "offset", { x: 0, y: 0 }),
          o(this, "position", undefined),
          o(this, "url", undefined),
          o(this, "item", undefined),
          (this.item = t),
          (this.position = t.coordinate);
      }
      return (
        i(e, [
          {
            key: "getHTML",
            value: function () {
              var e = this.item.markerOptions,
                t = D.DEFAULT,
                n = this.item.title,
                r = this.item.defaultImageUrl,
                i = r ? '<img src="'.concat(t.getUrl(r), '" />') : "",
                o = 'style="max-width: '
                  .concat(t.width, "px; color: ")
                  .concat(e.color, ';"'),
                c = '<h3 class="done-popup-title" '
                  .concat(o, ">")
                  .concat(n, "</h3>");
              return (
                this.url &&
                  (c = '<a href="'.concat(this.url, '">').concat(c, "</a>")),
                c + i
              );
            },
          },
        ]),
        e
      );
    })(),
    U = (function () {
      function t(e) {
        n(this, t), o(this, "item", undefined), (this.item = e);
      }
      return (
        i(t, null, [
          {
            key: "get",
            value: function (e) {
              return undefined !== e.innerItem ? e : new t(e);
            },
          },
        ]),
        i(t, [
          {
            key: "innerItem",
            get: function () {
              return this.item;
            },
          },
          {
            key: "id",
            get: function () {
              return this.item.global_id || this.item.id;
            },
          },
          {
            key: "title",
            get: function () {
              return this.item.title || "";
            },
          },
          {
            key: "defaultImageUrl",
            get: function () {
              var e = this.item.media_objects;
              if (e) {
                var t = e.find(function (e) {
                  return "default" === e.rel;
                });
                if (t) return t.url || undefined;
              }
            },
          },
          {
            key: "points",
            get: function () {
              var e = this.item.geo,
                t = e && e.geometry;
              if (t && t.value) return S.parsePolylineString(t.value);
            },
          },
          {
            key: "coordinate",
            get: function () {
              var e = this.item.geo,
                t = e && e.main;
              if (t && t.latitude && t.longitude)
                return new x({ lat: t.latitude, lng: t.longitude });
            },
          },
          {
            key: "pinType",
            get: function () {
              return this.item.type
                ? S.textToPinType(this.item.type)
                : e.PinType.Default;
            },
          },
          {
            key: "markerType",
            get: function () {
              return F.getMarkerType(this.pinType) || F.Default;
            },
          },
          {
            key: "markerOptions",
            get: function () {
              return a({}, this.markerType, {}, this.coordinate);
            },
          },
          {
            key: "popup",
            get: function () {
              return new _(this);
            },
          },
          {
            key: "geoPoint",
            get: function () {
              var e = this.coordinate,
                t = this.id,
                n = this.pinType;
              return {
                geometry: { coordinates: x.asArray(e), type: "Point" },
                id: t,
                properties: { icon: F.getKey(n), id: t, pinType: n },
                type: "Feature",
              };
            },
          },
          {
            key: "line",
            get: function () {
              return {
                id: this.id,
                layout: { arrows: !0 },
                points: this.points,
              };
            },
          },
        ]),
        t
      );
    })(),
    $ = (function () {
      function e(t) {
        n(this, e), o(this, "result", undefined), (this.result = t);
      }
      return (
        i(e, [
          {
            key: "toLayerData",
            value: function () {
              return this.toFeatureCollection();
            },
          },
          {
            key: "toFeature",
            value: function () {
              var e = this.result.items[0];
              return new U(e).geoPoint;
            },
          },
          {
            key: "toFeatureCollection",
            value: function () {
              var e = this.result.items
                  .map(function (e) {
                    return new U(e);
                  })
                  .filter(function (e) {
                    return e.coordinate;
                  }),
                t = E.calculateBounds(
                  e.map(function (e) {
                    return e.coordinate;
                  })
                );
              return {
                bbox: t ? t.toArray() : undefined,
                features: e.map(function (e) {
                  return e.geoPoint;
                }),
                type: "FeatureCollection",
              };
            },
          },
          {
            key: "first",
            value: function () {
              var e = this.result.items;
              if (e && e.length > 0) return new U(e[0]);
            },
          },
        ]),
        e
      );
    })();
  Function.prototype.$asyncbind = function e(n, r) {
    function i() {
      return o.apply(n, arguments);
    }
    Function.prototype.$asyncbind ||
      Object.defineProperty(Function.prototype, "$asyncbind", {
        value: e,
        enumerable: !1,
        configurable: !0,
        writable: !0,
      }),
      e.trampoline ||
        (e.trampoline = function (e, t, n, r, i) {
          return function o(c) {
            for (; c; ) {
              if (c.then) return (c = c.then(o, r)), i ? undefined : c;
              try {
                if (c.pop) {
                  if (c.length) return c.pop() ? t.call(e) : c;
                  c = n;
                } else c = c.call(e);
              } catch (e) {
                return r(e);
              }
            }
          };
        }),
      e.LazyThenable ||
        ((e.LazyThenable = (function () {
          function e(e) {
            return e && e instanceof Object && "function" == typeof e.then;
          }
          function t(n, r, i) {
            try {
              var o = i ? i(r) : r;
              if (n === o)
                return n.reject(new TypeError("Promise resolution loop"));
              e(o)
                ? o.then(
                    function (e) {
                      t(n, e);
                    },
                    function (e) {
                      n.reject(e);
                    }
                  )
                : n.resolve(o);
            } catch (e) {
              n.reject(e);
            }
          }
          function n(e) {}
          function r() {}
          function i(n, i) {
            var o = new r();
            try {
              this._resolver(
                function (r) {
                  return e(r) ? r.then(n, i) : t(o, r, n);
                },
                function (e) {
                  t(o, e, i);
                }
              );
            } catch (e) {
              t(o, e, i);
            }
            return o;
          }
          function o(e) {
            (this._resolver = e), (this.then = i);
          }
          return (
            (r.prototype = {
              resolve: n,
              reject: n,
              then: function (e, t) {
                (this.resolve = e), (this.reject = t);
              },
            }),
            (o.resolve = function (e) {
              return o.isThenable(e)
                ? e
                : {
                    then: function (t) {
                      return t(e);
                    },
                  };
            }),
            (o.isThenable = e),
            o
          );
        })()),
        (e.EagerThenable = e.Thenable = (e.EagerThenableFactory = function (e) {
          e =
            e ||
            ("object" ===
              ("undefined" == typeof process ? "undefined" : t(process)) &&
              process.nextTick) ||
            ("function" == typeof setImmediate && setImmediate) ||
            function (e) {
              setTimeout(e, 0);
            };
          var n = (function () {
            var t = [],
              n = 0,
              r = 1024;
            function i() {
              for (; t.length - n; ) {
                try {
                  t[n]();
                } catch (e) {}
                (t[n++] = undefined), n === r && (t.splice(0, r), (n = 0));
              }
            }
            return function (r) {
              t.push(r), t.length - n == 1 && e(i);
            };
          })();
          function r(e) {
            if (e) {
              var t = this;
              e(
                function (e) {
                  t.resolve(e);
                },
                function (e) {
                  t.reject(e);
                }
              );
            }
          }
          function i(e, t) {
            if ("function" == typeof e.y)
              try {
                var n = e.y.call(undefined, t);
                e.p.resolve(n);
              } catch (t) {
                e.p.reject(t);
              }
            else e.p.resolve(t);
          }
          function o(e, t) {
            if ("function" == typeof e.n)
              try {
                var n = e.n.call(undefined, t);
                e.p.resolve(n);
              } catch (t) {
                e.p.reject(t);
              }
            else e.p.reject(t);
          }
          return (
            (r.prototype = {
              resolve: function (e) {
                if (undefined === this.state) {
                  if (e === this)
                    return this.reject(
                      new TypeError("Attempt to resolve promise with self")
                    );
                  var r = this;
                  if (e && ("function" == typeof e || "object" === t(e)))
                    try {
                      var o = 0,
                        c = e.then;
                      if ("function" == typeof c)
                        return void c.call(
                          e,
                          function (e) {
                            o++ || r.resolve(e);
                          },
                          function (e) {
                            o++ || r.reject(e);
                          }
                        );
                    } catch (e) {
                      return void (o || this.reject(e));
                    }
                  (this.state = i),
                    (this.v = e),
                    r.c &&
                      n(function () {
                        for (var t = 0, n = r.c.length; t < n; t++)
                          i(r.c[t], e);
                      });
                }
              },
              reject: function (e) {
                if (undefined === this.state) {
                  (this.state = o), (this.v = e);
                  var t = this.c;
                  t &&
                    n(function () {
                      for (var n = 0, r = t.length; n < r; n++) o(t[n], e);
                    });
                }
              },
              then: function (e, t) {
                var i = new r(),
                  o = { y: e, n: t, p: i };
                if (undefined === this.state)
                  this.c ? this.c.push(o) : (this.c = [o]);
                else {
                  var c = this.state,
                    a = this.v;
                  n(function () {
                    c(o, a);
                  });
                }
                return i;
              },
            }),
            (r.resolve = function (e) {
              if (e && e instanceof r) return e;
              var t = new r();
              return t.resolve(e), t;
            }),
            (r.reject = function (e) {
              if (e && e instanceof r) return e;
              var t = new r();
              return t.reject(e), t;
            }),
            (r.version = "2.3.3-nodent"),
            r
          );
        })()));
    var o = this;
    switch (r) {
      case !0:
        return new e.Thenable(i);
      case 0:
        return new e.LazyThenable(i);
      case undefined:
        return (i.then = i), i;
      default:
        return function () {
          try {
            return o.apply(n, arguments);
          } catch (e) {
            return r(e);
          }
        };
    }
  };
  var H = (function (e) {
    function t(e, r) {
      var i;
      return (
        n(this, t),
        o(h((i = f(this, u(t).call(this, e)))), "request", undefined),
        o(h(i), "result", undefined),
        o(h(i), "searchAPI", undefined),
        (i.type = "meta"),
        (i.request = e.request),
        (i.result = e.result),
        (i.data = e.data),
        (i.searchAPI = r),
        i
      );
    }
    return (
      s(t, P),
      i(t, null, [
        {
          key: "init",
          value: function (e, n) {
            return e instanceof t ? e : new t(e, n.getDefaultSearchAPI());
          },
        },
      ]),
      i(t, [
        {
          key: "getLayerData",
          value: function () {
            return new Promise(
              function (e, t) {
                return this.data
                  ? e(this.data)
                  : this.result
                  ? e(this.convertData())
                  : e(this.fetchLayerData());
              }.$asyncbind(this)
            );
          },
        },
        {
          key: "findItem",
          value: function (e) {
            return new Promise(
              function (t, n) {
                var r, i;
                return this.getMetaData().then(
                  function (n) {
                    return (r = n) &&
                      r.items &&
                      (i = r.items.find(function (t) {
                        return t.global_id === e;
                      }))
                      ? t(i)
                      : t(Promise.reject());
                  }.$asyncbind(this, n),
                  n
                );
              }.$asyncbind(this)
            );
          },
        },
        {
          key: "getDefaultLayout",
          value: function () {
            return t.DEFAULT_LAYOUT;
          },
        },
        {
          key: "getDefaultPaint",
          value: function () {
            return t.DEFAULT_PAINT;
          },
        },
        {
          key: "convertData",
          value: function () {
            return (
              this.result && (this.data = new $(this.result).toLayerData()),
              this.data
            );
          },
        },
        {
          key: "fetchMetaData",
          value: function () {
            return new Promise(
              function (e, t) {
                if (this.request) {
                  var n = function () {
                      return i.call(this);
                    }.$asyncbind(this, t),
                    r = function (e) {
                      return n();
                    }.$asyncbind(this, t);
                  try {
                    return this.searchAPI.search(this.request).then(
                      function (e) {
                        return (this.result = e), n();
                      }.$asyncbind(this, r),
                      r
                    );
                  } catch (e) {
                    r(e);
                  }
                }
                function i() {
                  return e(this.result);
                }
                return i.call(this);
              }.$asyncbind(this)
            );
          },
        },
        {
          key: "getMetaData",
          value: function () {
            return new Promise(
              function (e, t) {
                return this.result ? e(this.result) : e(this.fetchMetaData());
              }.$asyncbind(this)
            );
          },
        },
        {
          key: "fetchLayerData",
          value: function () {
            return new Promise(
              function (e, t) {
                return this.fetchMetaData().then(
                  function (t) {
                    return e(t ? this.convertData() : this.data);
                  }.$asyncbind(this, t),
                  t
                );
              }.$asyncbind(this)
            );
          },
        },
        {
          key: "isLoading",
          get: function () {
            return this.searchAPI.isLoading;
          },
        },
      ]),
      t
    );
  })();
  o(H, "DEFAULT_LAYOUT", {}), o(H, "DEFAULT_PAINT", { color: "orange" });
  var N = (function () {
    function e(t, r) {
      n(this, e),
        o(this, "creators", undefined),
        o(this, "map", undefined),
        (this.map = t),
        (this.creators = {}),
        r &&
          (this.creators = r.reduce(function (e, t) {
            return (e[t.type] = new j(t)), e;
          }, this.creators));
    }
    return (
      i(e, null, [
        {
          key: "createDefault",
          value: function (t) {
            return new e(t, [
              {
                adder: function (e) {
                  return t.addLine(e);
                },
                clearer: function (e) {
                  return t.clearLine(e);
                },
                init: function (e) {
                  return M.init(e);
                },
                type: "line",
              },
              {
                adder: function (e) {
                  return t.loadMetaLayer(e);
                },
                clearer: function (e) {
                  return t.clearMetaLayer(e);
                },
                init: function (e) {
                  return H.init(e, t);
                },
                type: "meta",
              },
            ]);
          },
        },
      ]),
      i(e, [
        {
          key: "add",
          value: function (e) {
            this.creators[e.type] = new j(e);
          },
        },
        {
          key: "createLayer",
          value: function (e) {
            if (e.type) {
              var t = this.creators[e.type];
              return t && t.createLayer(e, this.map);
            }
            throw new Error(
              'Layer type "'.concat(e.type, '" is not supported!')
            );
          },
        },
        {
          key: "clearLayer",
          value: function (e) {
            if (e.type) {
              var t = this.creators[e.type];
              if (t) return void t.clearLayer(e, this.map);
            }
            throw new Error(
              'Layer type "'.concat(e.type, '" is not supported!')
            );
          },
        },
      ]),
      e
    );
  })();
  Function.prototype.$asyncbind = function e(n, r) {
    function i() {
      return o.apply(n, arguments);
    }
    Function.prototype.$asyncbind ||
      Object.defineProperty(Function.prototype, "$asyncbind", {
        value: e,
        enumerable: !1,
        configurable: !0,
        writable: !0,
      }),
      e.trampoline ||
        (e.trampoline = function (e, t, n, r, i) {
          return function o(c) {
            for (; c; ) {
              if (c.then) return (c = c.then(o, r)), i ? undefined : c;
              try {
                if (c.pop) {
                  if (c.length) return c.pop() ? t.call(e) : c;
                  c = n;
                } else c = c.call(e);
              } catch (e) {
                return r(e);
              }
            }
          };
        }),
      e.LazyThenable ||
        ((e.LazyThenable = (function () {
          function e(e) {
            return e && e instanceof Object && "function" == typeof e.then;
          }
          function t(n, r, i) {
            try {
              var o = i ? i(r) : r;
              if (n === o)
                return n.reject(new TypeError("Promise resolution loop"));
              e(o)
                ? o.then(
                    function (e) {
                      t(n, e);
                    },
                    function (e) {
                      n.reject(e);
                    }
                  )
                : n.resolve(o);
            } catch (e) {
              n.reject(e);
            }
          }
          function n(e) {}
          function r() {}
          function i(n, i) {
            var o = new r();
            try {
              this._resolver(
                function (r) {
                  return e(r) ? r.then(n, i) : t(o, r, n);
                },
                function (e) {
                  t(o, e, i);
                }
              );
            } catch (e) {
              t(o, e, i);
            }
            return o;
          }
          function o(e) {
            (this._resolver = e), (this.then = i);
          }
          return (
            (r.prototype = {
              resolve: n,
              reject: n,
              then: function (e, t) {
                (this.resolve = e), (this.reject = t);
              },
            }),
            (o.resolve = function (e) {
              return o.isThenable(e)
                ? e
                : {
                    then: function (t) {
                      return t(e);
                    },
                  };
            }),
            (o.isThenable = e),
            o
          );
        })()),
        (e.EagerThenable = e.Thenable = (e.EagerThenableFactory = function (e) {
          e =
            e ||
            ("object" ===
              ("undefined" == typeof process ? "undefined" : t(process)) &&
              process.nextTick) ||
            ("function" == typeof setImmediate && setImmediate) ||
            function (e) {
              setTimeout(e, 0);
            };
          var n = (function () {
            var t = [],
              n = 0,
              r = 1024;
            function i() {
              for (; t.length - n; ) {
                try {
                  t[n]();
                } catch (e) {}
                (t[n++] = undefined), n === r && (t.splice(0, r), (n = 0));
              }
            }
            return function (r) {
              t.push(r), t.length - n == 1 && e(i);
            };
          })();
          function r(e) {
            if (e) {
              var t = this;
              e(
                function (e) {
                  t.resolve(e);
                },
                function (e) {
                  t.reject(e);
                }
              );
            }
          }
          function i(e, t) {
            if ("function" == typeof e.y)
              try {
                var n = e.y.call(undefined, t);
                e.p.resolve(n);
              } catch (t) {
                e.p.reject(t);
              }
            else e.p.resolve(t);
          }
          function o(e, t) {
            if ("function" == typeof e.n)
              try {
                var n = e.n.call(undefined, t);
                e.p.resolve(n);
              } catch (t) {
                e.p.reject(t);
              }
            else e.p.reject(t);
          }
          return (
            (r.prototype = {
              resolve: function (e) {
                if (undefined === this.state) {
                  if (e === this)
                    return this.reject(
                      new TypeError("Attempt to resolve promise with self")
                    );
                  var r = this;
                  if (e && ("function" == typeof e || "object" === t(e)))
                    try {
                      var o = 0,
                        c = e.then;
                      if ("function" == typeof c)
                        return void c.call(
                          e,
                          function (e) {
                            o++ || r.resolve(e);
                          },
                          function (e) {
                            o++ || r.reject(e);
                          }
                        );
                    } catch (e) {
                      return void (o || this.reject(e));
                    }
                  (this.state = i),
                    (this.v = e),
                    r.c &&
                      n(function () {
                        for (var t = 0, n = r.c.length; t < n; t++)
                          i(r.c[t], e);
                      });
                }
              },
              reject: function (e) {
                if (undefined === this.state) {
                  (this.state = o), (this.v = e);
                  var t = this.c;
                  t &&
                    n(function () {
                      for (var n = 0, r = t.length; n < r; n++) o(t[n], e);
                    });
                }
              },
              then: function (e, t) {
                var i = new r(),
                  o = { y: e, n: t, p: i };
                if (undefined === this.state)
                  this.c ? this.c.push(o) : (this.c = [o]);
                else {
                  var c = this.state,
                    a = this.v;
                  n(function () {
                    c(o, a);
                  });
                }
                return i;
              },
            }),
            (r.resolve = function (e) {
              if (e && e instanceof r) return e;
              var t = new r();
              return t.resolve(e), t;
            }),
            (r.reject = function (e) {
              if (e && e instanceof r) return e;
              var t = new r();
              return t.reject(e), t;
            }),
            (r.version = "2.3.3-nodent"),
            r
          );
        })()));
    var o = this;
    switch (r) {
      case !0:
        return new e.Thenable(i);
      case 0:
        return new e.LazyThenable(i);
      case undefined:
        return (i.then = i), i;
      default:
        return function () {
          try {
            return o.apply(n, arguments);
          } catch (e) {
            return r(e);
          }
        };
    }
  };
  var G = (function () {
    function e() {
      n(this, e);
    }
    return (
      i(e, null, [
        {
          key: "fetchLight",
          value: function (e, t, n) {
            return new Promise(function (r, i) {
              e.template = "ET2014A_LIGHT.json";
              var o = w.prepareMetaSearch(e, t, n);
              return r(w.fetchJSON(o));
            });
          },
        },
        {
          key: "fetchFull",
          value: function (e, t, n) {
            return new Promise(function (r, i) {
              e.template = "ET2014A.json";
              var o = w.prepareMetaSearch(e, t, n);
              return r(w.fetchJSON(o));
            });
          },
        },
        {
          key: "fetchPredefinedConfig",
          value: function (e) {
            return new Promise(function (t, n) {
              var r = w.preparePredefinedCall(e, "json");
              return t(w.fetchJSON(r));
            });
          },
        },
      ]),
      e
    );
  })();
  Function.prototype.$asyncbind = function e(n, r) {
    function i() {
      return o.apply(n, arguments);
    }
    Function.prototype.$asyncbind ||
      Object.defineProperty(Function.prototype, "$asyncbind", {
        value: e,
        enumerable: !1,
        configurable: !0,
        writable: !0,
      }),
      e.trampoline ||
        (e.trampoline = function (e, t, n, r, i) {
          return function o(c) {
            for (; c; ) {
              if (c.then) return (c = c.then(o, r)), i ? undefined : c;
              try {
                if (c.pop) {
                  if (c.length) return c.pop() ? t.call(e) : c;
                  c = n;
                } else c = c.call(e);
              } catch (e) {
                return r(e);
              }
            }
          };
        }),
      e.LazyThenable ||
        ((e.LazyThenable = (function () {
          function e(e) {
            return e && e instanceof Object && "function" == typeof e.then;
          }
          function t(n, r, i) {
            try {
              var o = i ? i(r) : r;
              if (n === o)
                return n.reject(new TypeError("Promise resolution loop"));
              e(o)
                ? o.then(
                    function (e) {
                      t(n, e);
                    },
                    function (e) {
                      n.reject(e);
                    }
                  )
                : n.resolve(o);
            } catch (e) {
              n.reject(e);
            }
          }
          function n(e) {}
          function r() {}
          function i(n, i) {
            var o = new r();
            try {
              this._resolver(
                function (r) {
                  return e(r) ? r.then(n, i) : t(o, r, n);
                },
                function (e) {
                  t(o, e, i);
                }
              );
            } catch (e) {
              t(o, e, i);
            }
            return o;
          }
          function o(e) {
            (this._resolver = e), (this.then = i);
          }
          return (
            (r.prototype = {
              resolve: n,
              reject: n,
              then: function (e, t) {
                (this.resolve = e), (this.reject = t);
              },
            }),
            (o.resolve = function (e) {
              return o.isThenable(e)
                ? e
                : {
                    then: function (t) {
                      return t(e);
                    },
                  };
            }),
            (o.isThenable = e),
            o
          );
        })()),
        (e.EagerThenable = e.Thenable = (e.EagerThenableFactory = function (e) {
          e =
            e ||
            ("object" ===
              ("undefined" == typeof process ? "undefined" : t(process)) &&
              process.nextTick) ||
            ("function" == typeof setImmediate && setImmediate) ||
            function (e) {
              setTimeout(e, 0);
            };
          var n = (function () {
            var t = [],
              n = 0,
              r = 1024;
            function i() {
              for (; t.length - n; ) {
                try {
                  t[n]();
                } catch (e) {}
                (t[n++] = undefined), n === r && (t.splice(0, r), (n = 0));
              }
            }
            return function (r) {
              t.push(r), t.length - n == 1 && e(i);
            };
          })();
          function r(e) {
            if (e) {
              var t = this;
              e(
                function (e) {
                  t.resolve(e);
                },
                function (e) {
                  t.reject(e);
                }
              );
            }
          }
          function i(e, t) {
            if ("function" == typeof e.y)
              try {
                var n = e.y.call(undefined, t);
                e.p.resolve(n);
              } catch (t) {
                e.p.reject(t);
              }
            else e.p.resolve(t);
          }
          function o(e, t) {
            if ("function" == typeof e.n)
              try {
                var n = e.n.call(undefined, t);
                e.p.resolve(n);
              } catch (t) {
                e.p.reject(t);
              }
            else e.p.reject(t);
          }
          return (
            (r.prototype = {
              resolve: function (e) {
                if (undefined === this.state) {
                  if (e === this)
                    return this.reject(
                      new TypeError("Attempt to resolve promise with self")
                    );
                  var r = this;
                  if (e && ("function" == typeof e || "object" === t(e)))
                    try {
                      var o = 0,
                        c = e.then;
                      if ("function" == typeof c)
                        return void c.call(
                          e,
                          function (e) {
                            o++ || r.resolve(e);
                          },
                          function (e) {
                            o++ || r.reject(e);
                          }
                        );
                    } catch (e) {
                      return void (o || this.reject(e));
                    }
                  (this.state = i),
                    (this.v = e),
                    r.c &&
                      n(function () {
                        for (var t = 0, n = r.c.length; t < n; t++)
                          i(r.c[t], e);
                      });
                }
              },
              reject: function (e) {
                if (undefined === this.state) {
                  (this.state = o), (this.v = e);
                  var t = this.c;
                  t &&
                    n(function () {
                      for (var n = 0, r = t.length; n < r; n++) o(t[n], e);
                    });
                }
              },
              then: function (e, t) {
                var i = new r(),
                  o = { y: e, n: t, p: i };
                if (undefined === this.state)
                  this.c ? this.c.push(o) : (this.c = [o]);
                else {
                  var c = this.state,
                    a = this.v;
                  n(function () {
                    c(o, a);
                  });
                }
                return i;
              },
            }),
            (r.resolve = function (e) {
              if (e && e instanceof r) return e;
              var t = new r();
              return t.resolve(e), t;
            }),
            (r.reject = function (e) {
              if (e && e instanceof r) return e;
              var t = new r();
              return t.reject(e), t;
            }),
            (r.version = "2.3.3-nodent"),
            r
          );
        })()));
    var o = this;
    switch (r) {
      case !0:
        return new e.Thenable(i);
      case 0:
        return new e.LazyThenable(i);
      case undefined:
        return (i.then = i), i;
      default:
        return function () {
          try {
            return o.apply(n, arguments);
          } catch (e) {
            return r(e);
          }
        };
    }
  };
  var R = (function () {
      function e(t, r) {
        n(this, e),
          o(this, "isLoading", !1),
          o(this, "fetcher", undefined),
          o(this, "map", undefined),
          o(this, "abortController", undefined),
          (this.fetcher = t),
          (this.map = r);
      }
      return (
        i(e, [
          {
            key: "search",
            value: function (e, t) {
              return new Promise(
                function (n, r) {
                  var i = this;
                  this.abort(),
                    undefined === this.abortController &&
                      (this.abortController = new AbortController());
                  var o = this.abortController.signal;
                  return (
                    (this.isLoading = !0),
                    this.map.fire("meta-search", {
                      map: this.map,
                      loading: !0,
                    }),
                    n(
                      this.fetcher(e, t, { signal: o }).finally(function () {
                        i.map.fire("meta-search", { map: i.map, loading: !1 }),
                          (i.isLoading = !1);
                      })
                    )
                  );
                }.$asyncbind(this)
              );
            },
          },
          {
            key: "abort",
            value: function () {
              this.abortController &&
                (this.abortController.abort(),
                (this.abortController = undefined));
            },
          },
        ]),
        e
      );
    })(),
    V = (function () {
      function e() {
        n(this, e);
      }
      return (
        i(e, null, [
          {
            key: "newLightSearch",
            value: function (e) {
              return new R(G.fetchLight, e);
            },
          },
          {
            key: "newFullSearch",
            value: function (e) {
              return new R(G.fetchFull, e);
            },
          },
        ]),
        e
      );
    })();
  Function.prototype.$asyncbind = function e(n, r) {
    function i() {
      return o.apply(n, arguments);
    }
    Function.prototype.$asyncbind ||
      Object.defineProperty(Function.prototype, "$asyncbind", {
        value: e,
        enumerable: !1,
        configurable: !0,
        writable: !0,
      }),
      e.trampoline ||
        (e.trampoline = function (e, t, n, r, i) {
          return function o(c) {
            for (; c; ) {
              if (c.then) return (c = c.then(o, r)), i ? undefined : c;
              try {
                if (c.pop) {
                  if (c.length) return c.pop() ? t.call(e) : c;
                  c = n;
                } else c = c.call(e);
              } catch (e) {
                return r(e);
              }
            }
          };
        }),
      e.LazyThenable ||
        ((e.LazyThenable = (function () {
          function e(e) {
            return e && e instanceof Object && "function" == typeof e.then;
          }
          function t(n, r, i) {
            try {
              var o = i ? i(r) : r;
              if (n === o)
                return n.reject(new TypeError("Promise resolution loop"));
              e(o)
                ? o.then(
                    function (e) {
                      t(n, e);
                    },
                    function (e) {
                      n.reject(e);
                    }
                  )
                : n.resolve(o);
            } catch (e) {
              n.reject(e);
            }
          }
          function n(e) {}
          function r() {}
          function i(n, i) {
            var o = new r();
            try {
              this._resolver(
                function (r) {
                  return e(r) ? r.then(n, i) : t(o, r, n);
                },
                function (e) {
                  t(o, e, i);
                }
              );
            } catch (e) {
              t(o, e, i);
            }
            return o;
          }
          function o(e) {
            (this._resolver = e), (this.then = i);
          }
          return (
            (r.prototype = {
              resolve: n,
              reject: n,
              then: function (e, t) {
                (this.resolve = e), (this.reject = t);
              },
            }),
            (o.resolve = function (e) {
              return o.isThenable(e)
                ? e
                : {
                    then: function (t) {
                      return t(e);
                    },
                  };
            }),
            (o.isThenable = e),
            o
          );
        })()),
        (e.EagerThenable = e.Thenable = (e.EagerThenableFactory = function (e) {
          e =
            e ||
            ("object" ===
              ("undefined" == typeof process ? "undefined" : t(process)) &&
              process.nextTick) ||
            ("function" == typeof setImmediate && setImmediate) ||
            function (e) {
              setTimeout(e, 0);
            };
          var n = (function () {
            var t = [],
              n = 0,
              r = 1024;
            function i() {
              for (; t.length - n; ) {
                try {
                  t[n]();
                } catch (e) {}
                (t[n++] = undefined), n === r && (t.splice(0, r), (n = 0));
              }
            }
            return function (r) {
              t.push(r), t.length - n == 1 && e(i);
            };
          })();
          function r(e) {
            if (e) {
              var t = this;
              e(
                function (e) {
                  t.resolve(e);
                },
                function (e) {
                  t.reject(e);
                }
              );
            }
          }
          function i(e, t) {
            if ("function" == typeof e.y)
              try {
                var n = e.y.call(undefined, t);
                e.p.resolve(n);
              } catch (t) {
                e.p.reject(t);
              }
            else e.p.resolve(t);
          }
          function o(e, t) {
            if ("function" == typeof e.n)
              try {
                var n = e.n.call(undefined, t);
                e.p.resolve(n);
              } catch (t) {
                e.p.reject(t);
              }
            else e.p.reject(t);
          }
          return (
            (r.prototype = {
              resolve: function (e) {
                if (undefined === this.state) {
                  if (e === this)
                    return this.reject(
                      new TypeError("Attempt to resolve promise with self")
                    );
                  var r = this;
                  if (e && ("function" == typeof e || "object" === t(e)))
                    try {
                      var o = 0,
                        c = e.then;
                      if ("function" == typeof c)
                        return void c.call(
                          e,
                          function (e) {
                            o++ || r.resolve(e);
                          },
                          function (e) {
                            o++ || r.reject(e);
                          }
                        );
                    } catch (e) {
                      return void (o || this.reject(e));
                    }
                  (this.state = i),
                    (this.v = e),
                    r.c &&
                      n(function () {
                        for (var t = 0, n = r.c.length; t < n; t++)
                          i(r.c[t], e);
                      });
                }
              },
              reject: function (e) {
                if (undefined === this.state) {
                  (this.state = o), (this.v = e);
                  var t = this.c;
                  t &&
                    n(function () {
                      for (var n = 0, r = t.length; n < r; n++) o(t[n], e);
                    });
                }
              },
              then: function (e, t) {
                var i = new r(),
                  o = { y: e, n: t, p: i };
                if (undefined === this.state)
                  this.c ? this.c.push(o) : (this.c = [o]);
                else {
                  var c = this.state,
                    a = this.v;
                  n(function () {
                    c(o, a);
                  });
                }
                return i;
              },
            }),
            (r.resolve = function (e) {
              if (e && e instanceof r) return e;
              var t = new r();
              return t.resolve(e), t;
            }),
            (r.reject = function (e) {
              if (e && e instanceof r) return e;
              var t = new r();
              return t.reject(e), t;
            }),
            (r.version = "2.3.3-nodent"),
            r
          );
        })()));
    var o = this;
    switch (r) {
      case !0:
        return new e.Thenable(i);
      case 0:
        return new e.LazyThenable(i);
      case undefined:
        return (i.then = i), i;
      default:
        return function () {
          try {
            return o.apply(n, arguments);
          } catch (e) {
            return r(e);
          }
        };
    }
  };
  var B = (function () {
      function t(e) {
        var r = this;
        if (
          (n(this, t),
          o(this, "map", undefined),
          o(this, "config", undefined),
          o(this, "predefinedConfig", undefined),
          o(this, "mapStyles", new g()),
          o(this, "defaultSearchAPI", undefined),
          o(this, "fullSearchAPI", undefined),
          o(this, "layerFactory", undefined),
          o(this, "layers", []),
          o(this, "eventManager", new T()),
          o(this, "experience", undefined),
          o(this, "currentTour", undefined),
          o(this, "currentMetaLayer", undefined),
          o(this, "currentPopup", undefined),
          o(this, "selectedMarker", undefined),
          o(this, "selectedItem", undefined),
          this.isMapOptions(e))
        ) {
          var i = e;
          i.experience
            ? this.setExperience(i.experience).then(function () {
                r.mapStyles = b.toMapStyles(r.config);
                var e = b.toCameraOptions(r.config);
                undefined === i.center && (i.center = e.center),
                  undefined === i.zoom && (i.zoom = e.zoom),
                  r.checkMapOptions(i),
                  (r.map = r.createMap(i));
              })
            : (this.checkMapOptions(i), (this.map = this.createMap(i)));
        } else this.map = e;
        (this.defaultSearchAPI = V.newLightSearch(this)),
          (this.fullSearchAPI = V.newFullSearch(this)),
          (this.layerFactory = this.initLayerFactory());
      }
      return (
        i(t, [
          {
            key: "setExperience",
            value: function (e) {
              return new Promise(
                function (t, n) {
                  var r = this;
                  return this.experience !== e
                    ? ((this.experience = e),
                      (this.predefinedConfig = undefined),
                      (this.config = undefined),
                      t(
                        k
                          .fetchConfig({ experience: e })
                          .then(function (e) {
                            return (r.config = e);
                          })
                          .then(function () {
                            return r.fire("experience-changed", {
                              map: r,
                              experience: e,
                            });
                          })
                          .then(function () {
                            return Promise.resolve();
                          })
                      ))
                    : t(this.config ? Promise.resolve() : Promise.reject());
                }.$asyncbind(this)
              );
            },
          },
          {
            key: "getExperience",
            value: function () {
              return this.experience;
            },
          },
          {
            key: "addLayer",
            value: function (e) {
              return this.layerFactory.createLayer(this.checkLayer(e));
            },
          },
          {
            key: "getDefaultSearchAPI",
            value: function () {
              return this.defaultSearchAPI;
            },
          },
          {
            key: "getFullSearchAPI",
            value: function () {
              return this.fullSearchAPI;
            },
          },
          {
            key: "showContent",
            value: function (e) {
              return new Promise(
                function (t, n) {
                  var r = this;
                  return t(
                    this.loadMetaLayer(
                      a(
                        { id: "META" },
                        this.isMetaResult(e)
                          ? { result: e }
                          : { request: this.fillRequest(e) }
                      )
                    ).then(function (e) {
                      return (r.currentMetaLayer = e);
                    })
                  );
                }.$asyncbind(this)
              );
            },
          },
          {
            key: "getMetaLayer",
            value: function () {
              return this.currentMetaLayer;
            },
          },
          {
            key: "showContentUnclustered",
            value: function (e) {
              return new Promise(
                function (t, n) {
                  var r = this;
                  return t(
                    V.newLightSearch(this)
                      .search(e)
                      .then(function (e) {
                        e.items &&
                          e.items.forEach(function (e) {
                            var t = new U(e),
                              n = t.markerOptions;
                            (n.popup = t.popup), r.addMarker(n);
                          });
                      })
                  );
                }.$asyncbind(this)
              );
            },
          },
          {
            key: "showTour",
            value: function (e) {
              return new Promise(
                function (t, n) {
                  var r = this;
                  if (!this.experience)
                    return n(new Error("No experience name defined!"));
                  if (this.currentTour) {
                    if (this.currentTour.id === e)
                      return (
                        this.fitBounds(this.currentTour.points),
                        t(Promise.resolve(this.currentTour))
                      );
                    this.clearLine(this.currentTour);
                  }
                  return t(
                    this.getFullSearchAPI()
                      .search(
                        {
                          experience: this.experience,
                          limit: 1,
                          q: "globalid:" + e,
                          type: "Tour",
                        },
                        { geometryOnly: !0 }
                      )
                      .then(function (e) {
                        var t = new $(e).first();
                        if (t) {
                          var n = t.line;
                          return (
                            (n.persistent = "none"),
                            r.addLine(n),
                            r.fitBounds(n.points),
                            (r.currentTour = n),
                            Promise.resolve(n)
                          );
                        }
                        return Promise.reject();
                      })
                  );
                }.$asyncbind(this)
              );
            },
          },
          {
            key: "clearTour",
            value: function () {
              this.removeCurrentTour();
            },
          },
          {
            key: "createPopup",
            value: function (e) {
              return U.get(e).popup;
            },
          },
          {
            key: "findItem",
            value: function (e) {
              return new Promise(
                function (t, n) {
                  var r = this.getMetaLayer();
                  return t(
                    r
                      ? r.findItem(e).then(function (e) {
                          return new U(e);
                        })
                      : Promise.reject()
                  );
                }.$asyncbind(this)
              );
            },
          },
          {
            key: "showItem",
            value: function (t, n) {
              this.clearSelection();
              var r = U.get(t);
              (this.selectedMarker = this.addMarker(r.markerOptions)),
                n && ((n.center = r.coordinate), n.center && this.flyTo(n)),
                r.pinType === e.PinType.Tour && this.showTour(r.id);
            },
          },
          {
            key: "showPopup",
            value: function (e) {
              this.removeCurrentPopup(), (this.currentPopup = this.addPopup(e));
            },
          },
          {
            key: "hidePopup",
            value: function () {
              this.removeCurrentPopup();
            },
          },
          {
            key: "selectItem",
            value: function (e, t) {
              (this.selectedItem = U.get(e)),
                this.fire("item-selected", {
                  map: this,
                  item: this.selectedItem,
                  data: t,
                });
            },
          },
          {
            key: "unselectAll",
            value: function () {
              (this.selectedItem = undefined), this.clearSelection();
            },
          },
          {
            key: "getSelectedItem",
            value: function () {
              return this.selectedItem;
            },
          },
          {
            key: "getPredefinedConfig",
            value: function () {
              return new Promise(
                function (e, t) {
                  var n = this;
                  return this.experience
                    ? this.predefinedConfig
                      ? e(Promise.resolve(this.predefinedConfig))
                      : e(
                          G.fetchPredefinedConfig(this.experience).then(
                            function (e) {
                              return (n.predefinedConfig = e);
                            }
                          )
                        )
                    : e(Promise.reject());
                }.$asyncbind(this)
              );
            },
          },
          {
            key: "on",
            value: function (e, t) {
              this.eventManager.add(e, t);
            },
          },
          {
            key: "off",
            value: function (e, t) {
              this.eventManager.remove(e, t);
            },
          },
          {
            key: "fire",
            value: function (e, t) {
              this.eventManager.fire(e, t);
            },
          },
          {
            key: "restoreTour",
            value: function () {
              this.currentTour && this.addLine(this.currentTour);
            },
          },
          {
            key: "checkLayer",
            value: function (e) {
              if (undefined === e.persistent || "persist" === e.persistent) {
                e.persistent = "saved";
                var t = this.layers.findIndex(function (t) {
                  return t.id === e.id;
                });
                -1 !== t ? (this.layers[t] = e) : this.layers.push(e);
              }
              return e;
            },
          },
          {
            key: "initLayer",
            value: function (e, t) {
              return this.checkLayer(t(e, this));
            },
          },
          {
            key: "restoreLayers",
            value: function () {
              return new Promise(
                function (e, t) {
                  var n = this;
                  return e(
                    this.layers.forEach(function (e) {
                      return n.layerFactory.createLayer(e);
                    })
                  );
                }.$asyncbind(this)
              );
            },
          },
          {
            key: "clearLayers",
            value: function () {
              return new Promise(
                function (e, t) {
                  var n = this;
                  return e(
                    this.layers.forEach(function (e) {
                      return n.layerFactory.clearLayer(e);
                    })
                  );
                }.$asyncbind(this)
              );
            },
          },
          {
            key: "initLayerFactory",
            value: function () {
              return N.createDefault(this);
            },
          },
          {
            key: "clearSelection",
            value: function () {
              this.removeCurrentPopup(),
                this.removeCurrentMarker(),
                this.removeCurrentTour();
            },
          },
          {
            key: "isMapOptions",
            value: function (e) {
              return undefined !== e.container;
            },
          },
          {
            key: "isMetaResult",
            value: function (e) {
              return undefined !== e.items;
            },
          },
          {
            key: "fillRequest",
            value: function (e) {
              return (
                !e.experience &&
                  this.experience &&
                  (e.experience = this.experience),
                !this.experience &&
                  e.experience &&
                  this.setExperience(e.experience),
                e
              );
            },
          },
          {
            key: "checkMapOptions",
            value: function (e) {
              if (undefined === e.center || undefined === e.zoom)
                throw new Error(
                  "Invalid IMapOptions: Please set experience or camera options (center, zoom)!"
                );
            },
          },
          {
            key: "removeCurrentPopup",
            value: function () {
              this.currentPopup &&
                (this.currentPopup.remove(), (this.currentPopup = undefined));
            },
          },
          {
            key: "removeCurrentMarker",
            value: function () {
              this.selectedMarker &&
                (this.selectedMarker.remove(),
                (this.selectedMarker = undefined));
            },
          },
          {
            key: "removeCurrentTour",
            value: function () {
              this.currentTour &&
                (this.clearLine(this.currentTour),
                (this.currentTour = undefined));
            },
          },
        ]),
        t
      );
    })(),
    q = (function (e) {
      function t() {
        return n(this, t), f(this, u(t).apply(this, arguments));
      }
      return (
        s(t, B),
        i(t, [
          {
            key: "getMap",
            value: function () {
              return this.map;
            },
          },
          {
            key: "createMap",
            value: function (e) {
              return (
                (this.map = L.map(
                  e.container,
                  a(
                    {},
                    e.center && {
                      center: L.latLng(e.center.lat, e.center.lng),
                    },
                    { zoom: e.zoom, zoomControl: !0 }
                  )
                )),
                L.tileLayer(
                  "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                  {
                    attribution:
                      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                  }
                ).addTo(this.map),
                this.map
              );
            },
          },
          {
            key: "isLoaded",
            value: function () {
              return undefined !== this.map;
            },
          },
          {
            key: "getContainer",
            value: function () {
              return this.map.getContainer();
            },
          },
          {
            key: "setMapStyle",
            value: function (e) {
              throw new Error("not implemented");
            },
          },
          {
            key: "resize",
            value: function () {
              throw new Error("not implemented");
            },
          },
          {
            key: "addMarker",
            value: function (e) {
              throw new Error("not implemented");
            },
          },
          {
            key: "addLocationMarker",
            value: function (e) {
              throw new Error("not implemented");
            },
          },
          {
            key: "addLine",
            value: function (e) {
              throw new Error("not implemented");
            },
          },
          {
            key: "clearLine",
            value: function (e) {
              throw new Error("not implemented");
            },
          },
          {
            key: "addLineArrows",
            value: function (e) {
              throw new Error("not implemented");
            },
          },
          {
            key: "loadMetaLayer",
            value: function (e) {
              throw new Error("not implemented");
            },
          },
          {
            key: "clearMetaLayer",
            value: function (e) {
              throw new Error("not implemented");
            },
          },
          {
            key: "fitBounds",
            value: function (e) {
              throw new Error("not implemented");
            },
          },
          {
            key: "fitLayer",
            value: function (e) {
              throw new Error("not implemented");
            },
          },
          {
            key: "isFullscreen",
            value: function () {
              throw new Error("not implemented");
            },
          },
          {
            key: "setFullscreen",
            value: function (e) {
              throw new Error("not implemented");
            },
          },
          {
            key: "addPopup",
            value: function (e) {
              throw new Error("not implemented");
            },
          },
          {
            key: "flyTo",
            value: function (e) {
              throw new Error("not implemented");
            },
          },
        ]),
        t
      );
    })(),
    J = (function () {
      function e() {
        n(this, e);
      }
      return (
        i(e, [
          {
            key: "canHandle",
            value: function (e) {
              return e instanceof L.Map;
            },
          },
          {
            key: "isSupported",
            value: function () {
              return undefined !== L && undefined !== L.map;
            },
          },
          {
            key: "createMap",
            value: function (e) {
              return new q(e);
            },
          },
          {
            key: "useMap",
            value: function (e) {
              return new q(e);
            },
          },
        ]),
        e
      );
    })(),
    K = (function () {
      function e() {
        n(this, e);
      }
      return (
        i(e, null, [
          {
            key: "createVectorMarkerDiv",
            value: function (e) {
              var t = document.createElement("div");
              t.classList.add("done-marker");
              var n = I.Marker.create({ fill: e.color });
              return (t.innerHTML = n.renderSvg(e.symbol)), t;
            },
          },
        ]),
        e
      );
    })(),
    Y = (function () {
      function e(t) {
        n(this, e),
          o(this, "overlay", undefined),
          o(this, "disableAction", undefined),
          (this.disableAction = t);
      }
      return (
        i(e, [
          {
            key: "addTo",
            value: function (e) {
              var t = this,
                n = e.getContainer();
              (this.overlay = document.createElement("div")),
                (this.overlay.className = "done-finger-hint"),
                this.overlay.appendChild(
                  document.createTextNode(
                    "Kartenauschnitt ändern mit zwei Fingern"
                  )
                ),
                n.appendChild(this.overlay),
                n.addEventListener("touchstart", function (e) {
                  return t.ontouch(e);
                }),
                n.addEventListener("touchend", function (e) {
                  return t.ontouch(e);
                });
            },
          },
          {
            key: "ontouch",
            value: function (e) {
              this.overlay &&
                ("touchstart" === e.type && 1 === e.touches.length
                  ? (this.disableAction &&
                      (this.disableAction(), (this.disableAction = undefined)),
                    this.overlay.classList.add("done-finger-hint-show"))
                  : this.overlay.classList.remove("done-finger-hint-show"));
            },
          },
        ]),
        e
      );
    })(),
    W = (function () {
      function e() {
        n(this, e);
      }
      return (
        i(e, null, [
          {
            key: "convertLineLayout",
            value: function (e) {
              return (
                (e &&
                  a(
                    {},
                    e.cap && { "line-cap": e.cap },
                    {},
                    e.visibility && { visibility: e.visibility }
                  )) ||
                {}
              );
            },
          },
          {
            key: "convertLinePaint",
            value: function (e) {
              return (
                (e &&
                  a(
                    {},
                    e.blur && { "line-blur": e.blur },
                    {},
                    e.color && { "line-color": e.color },
                    {},
                    e.opacity && { "line-opacity": e.opacity },
                    {},
                    e.width && { "line-width": e.width }
                  )) ||
                {}
              );
            },
          },
          {
            key: "createPopup",
            value: function (e) {
              var t = new mapboxgl.Popup(
                a(
                  {
                    anchor: e.anchor,
                    className: e.className,
                    closeButton: e.closeButton,
                  },
                  e.maxWidth && { maxWidth: e.maxWidth },
                  {},
                  e.offset && { offset: C.asArray(e.offset) }
                )
              ).setHTML(e.getHTML());
              return e.position && t.setLngLat(x.asArray(e.position)), t;
            },
          },
        ]),
        e
      );
    })();
  Function.prototype.$asyncbind = function e(n, r) {
    function i() {
      return o.apply(n, arguments);
    }
    Function.prototype.$asyncbind ||
      Object.defineProperty(Function.prototype, "$asyncbind", {
        value: e,
        enumerable: !1,
        configurable: !0,
        writable: !0,
      }),
      e.trampoline ||
        (e.trampoline = function (e, t, n, r, i) {
          return function o(c) {
            for (; c; ) {
              if (c.then) return (c = c.then(o, r)), i ? undefined : c;
              try {
                if (c.pop) {
                  if (c.length) return c.pop() ? t.call(e) : c;
                  c = n;
                } else c = c.call(e);
              } catch (e) {
                return r(e);
              }
            }
          };
        }),
      e.LazyThenable ||
        ((e.LazyThenable = (function () {
          function e(e) {
            return e && e instanceof Object && "function" == typeof e.then;
          }
          function t(n, r, i) {
            try {
              var o = i ? i(r) : r;
              if (n === o)
                return n.reject(new TypeError("Promise resolution loop"));
              e(o)
                ? o.then(
                    function (e) {
                      t(n, e);
                    },
                    function (e) {
                      n.reject(e);
                    }
                  )
                : n.resolve(o);
            } catch (e) {
              n.reject(e);
            }
          }
          function n(e) {}
          function r() {}
          function i(n, i) {
            var o = new r();
            try {
              this._resolver(
                function (r) {
                  return e(r) ? r.then(n, i) : t(o, r, n);
                },
                function (e) {
                  t(o, e, i);
                }
              );
            } catch (e) {
              t(o, e, i);
            }
            return o;
          }
          function o(e) {
            (this._resolver = e), (this.then = i);
          }
          return (
            (r.prototype = {
              resolve: n,
              reject: n,
              then: function (e, t) {
                (this.resolve = e), (this.reject = t);
              },
            }),
            (o.resolve = function (e) {
              return o.isThenable(e)
                ? e
                : {
                    then: function (t) {
                      return t(e);
                    },
                  };
            }),
            (o.isThenable = e),
            o
          );
        })()),
        (e.EagerThenable = e.Thenable = (e.EagerThenableFactory = function (e) {
          e =
            e ||
            ("object" ===
              ("undefined" == typeof process ? "undefined" : t(process)) &&
              process.nextTick) ||
            ("function" == typeof setImmediate && setImmediate) ||
            function (e) {
              setTimeout(e, 0);
            };
          var n = (function () {
            var t = [],
              n = 0,
              r = 1024;
            function i() {
              for (; t.length - n; ) {
                try {
                  t[n]();
                } catch (e) {}
                (t[n++] = undefined), n === r && (t.splice(0, r), (n = 0));
              }
            }
            return function (r) {
              t.push(r), t.length - n == 1 && e(i);
            };
          })();
          function r(e) {
            if (e) {
              var t = this;
              e(
                function (e) {
                  t.resolve(e);
                },
                function (e) {
                  t.reject(e);
                }
              );
            }
          }
          function i(e, t) {
            if ("function" == typeof e.y)
              try {
                var n = e.y.call(undefined, t);
                e.p.resolve(n);
              } catch (t) {
                e.p.reject(t);
              }
            else e.p.resolve(t);
          }
          function o(e, t) {
            if ("function" == typeof e.n)
              try {
                var n = e.n.call(undefined, t);
                e.p.resolve(n);
              } catch (t) {
                e.p.reject(t);
              }
            else e.p.reject(t);
          }
          return (
            (r.prototype = {
              resolve: function (e) {
                if (undefined === this.state) {
                  if (e === this)
                    return this.reject(
                      new TypeError("Attempt to resolve promise with self")
                    );
                  var r = this;
                  if (e && ("function" == typeof e || "object" === t(e)))
                    try {
                      var o = 0,
                        c = e.then;
                      if ("function" == typeof c)
                        return void c.call(
                          e,
                          function (e) {
                            o++ || r.resolve(e);
                          },
                          function (e) {
                            o++ || r.reject(e);
                          }
                        );
                    } catch (e) {
                      return void (o || this.reject(e));
                    }
                  (this.state = i),
                    (this.v = e),
                    r.c &&
                      n(function () {
                        for (var t = 0, n = r.c.length; t < n; t++)
                          i(r.c[t], e);
                      });
                }
              },
              reject: function (e) {
                if (undefined === this.state) {
                  (this.state = o), (this.v = e);
                  var t = this.c;
                  t &&
                    n(function () {
                      for (var n = 0, r = t.length; n < r; n++) o(t[n], e);
                    });
                }
              },
              then: function (e, t) {
                var i = new r(),
                  o = { y: e, n: t, p: i };
                if (undefined === this.state)
                  this.c ? this.c.push(o) : (this.c = [o]);
                else {
                  var c = this.state,
                    a = this.v;
                  n(function () {
                    c(o, a);
                  });
                }
                return i;
              },
            }),
            (r.resolve = function (e) {
              if (e && e instanceof r) return e;
              var t = new r();
              return t.resolve(e), t;
            }),
            (r.reject = function (e) {
              if (e && e instanceof r) return e;
              var t = new r();
              return t.reject(e), t;
            }),
            (r.version = "2.3.3-nodent"),
            r
          );
        })()));
    var o = this;
    switch (r) {
      case !0:
        return new e.Thenable(i);
      case 0:
        return new e.LazyThenable(i);
      case undefined:
        return (i.then = i), i;
      default:
        return function () {
          try {
            return o.apply(n, arguments);
          } catch (e) {
            return r(e);
          }
        };
    }
  };
  var Z = (function () {
      function t(e, r) {
        n(this, t),
          o(this, "cancel", !1),
          o(this, "onMouseEnter", undefined),
          o(this, "onMouseLeave", undefined),
          o(this, "onClick", undefined),
          o(this, "onClusterClick", undefined),
          o(this, "dMap", undefined),
          o(this, "metaLayer", undefined),
          (this.dMap = e),
          (this.metaLayer = r);
      }
      return (
        i(t, [
          {
            key: "addTo",
            value: function (t) {
              return new Promise(
                function (n, r) {
                  var i = this,
                    o = this.metaLayer.id;
                  return n(
                    this.metaLayer.getLayerData().then(function (n) {
                      if (i.cancel) return i.metaLayer;
                      var r = {
                        cluster: !0,
                        clusterProperties: {
                          maxPinType: ["max", ["get", "pinType"]],
                          minPinType: ["min", ["get", "pinType"]],
                        },
                        clusterRadius: 40,
                        data: n,
                        type: "geojson",
                      };
                      return (
                        t.addSource(o, r),
                        t.addLayer({
                          filter: ["has", "point_count"],
                          id: "meta-clusters",
                          paint: {
                            "circle-blur": 0.55,
                            "circle-color": [
                              "case",
                              [
                                "==",
                                ["get", "minPinType"],
                                ["get", "maxPinType"],
                              ],
                              [
                                "interpolate-hcl",
                                ["linear"],
                                ["get", "maxPinType"],
                                e.PinType.Default,
                                F.Default.color,
                                e.PinType.Hotel,
                                F.Hotel.color,
                                e.PinType.Event,
                                F.Event.color,
                                e.PinType.Gastro,
                                F.Gastro.color,
                                e.PinType.Tour,
                                F.Tour.color,
                                e.PinType.POI,
                                F.POI.color,
                                e.PinType.City,
                                F.City.color,
                                e.PinType.Area,
                                F.Area.color,
                                e.PinType.Package,
                                F.Package.color,
                                e.PinType.Article,
                                F.Article.color,
                              ],
                              "white",
                            ],
                            "circle-radius": {
                              property: "point_count",
                              stops: [
                                [0, 25],
                                [25, 30],
                                [50, 35],
                                [100, 40],
                              ],
                              type: "interval",
                            },
                            "circle-stroke-color": "rgba(0,0,0,128)",
                            "circle-stroke-width": 0,
                          },
                          source: o,
                          type: "circle",
                        }),
                        t.addLayer({
                          filter: ["has", "point_count"],
                          id: "meta-cluster-count",
                          layout: {
                            "text-allow-overlap": !0,
                            "text-field": "{point_count}",
                            "text-ignore-placement": !0,
                            "text-size": 14,
                          },
                          paint: {
                            "text-halo-color": "white",
                            "text-halo-width": 1,
                          },
                          source: o,
                          type: "symbol",
                        }),
                        t.addLayer({
                          filter: ["!has", "point_count"],
                          id: "meta-unclustered",
                          layout: {
                            "icon-allow-overlap": !0,
                            "icon-anchor": "bottom",
                            "icon-ignore-placement": !0,
                            "icon-image": "{icon}",
                            "icon-size": 1,
                            "text-allow-overlap": !0,
                            "text-ignore-placement": !0,
                          },
                          source: o,
                          type: "symbol",
                        }),
                        i.addMouseEvents(t),
                        i.addClickEvents(t),
                        i.metaLayer
                      );
                    })
                  );
                }.$asyncbind(this)
              );
            },
          },
          {
            key: "removeFrom",
            value: function (e) {
              (this.cancel = !0),
                this.onMouseEnter &&
                  (e.off("mouseenter", "meta-unclustered", this.onMouseEnter),
                  e.off("mouseenter", "meta-clusters", this.onMouseEnter),
                  (this.onMouseEnter = undefined)),
                this.onMouseLeave &&
                  (e.off("mouseleave", "meta-unclustered", this.onMouseLeave),
                  e.off("mouseleave", "meta-clusters", this.onMouseLeave),
                  (this.onMouseLeave = undefined)),
                this.onClick &&
                  (e.off("click", "meta-unclustered", this.onClick),
                  (this.onClick = undefined)),
                this.onClusterClick &&
                  (e.off("click", "meta-clusters", this.onClusterClick),
                  (this.onClusterClick = undefined));
              var t = this.metaLayer.id;
              undefined !== e.getSource(t) &&
                (e.removeLayer("meta-unclustered"),
                e.removeLayer("meta-cluster-count"),
                e.removeLayer("meta-clusters"),
                e.removeSource(t));
            },
          },
          {
            key: "addClickEvents",
            value: function (e) {
              var t = this;
              (this.onClick = function (e) {
                if (e.features && e.features.length > 0) {
                  var n = e.features[0].properties.id;
                  t.metaLayer.findItem(n).then(function (e) {
                    t.dMap.selectItem(e);
                  });
                }
              }),
                e.on("click", "meta-unclustered", this.onClick),
                (this.onClusterClick = function (t) {
                  e.flyTo({ center: t.lngLat, zoom: e.getZoom() + 1 });
                }),
                e.on("click", "meta-clusters", this.onClusterClick);
            },
          },
          {
            key: "addMouseEvents",
            value: function (e) {
              (this.onMouseEnter = function () {
                e.getCanvas().style.cursor = "pointer";
              }),
                (this.onMouseLeave = function () {
                  e.getCanvas().style.cursor = "";
                }),
                e.on("mouseenter", "meta-unclustered", this.onMouseEnter),
                e.on("mouseleave", "meta-unclustered", this.onMouseLeave),
                e.on("mouseenter", "meta-clusters", this.onMouseEnter),
                e.on("mouseleave", "meta-clusters", this.onMouseLeave);
            },
          },
        ]),
        t
      );
    })(),
    Q = (function () {
      function e(t, r, i) {
        n(this, e),
          o(this, "dMap", undefined),
          o(this, "defaultId", undefined),
          o(this, "ctrl", undefined),
          o(this, "btn", undefined),
          o(this, "list", undefined),
          o(this, "styles", undefined),
          (this.dMap = t),
          (this.defaultId = r),
          (this.styles = i);
      }
      return (
        i(e, [
          {
            key: "getDefaultPosition",
            value: function () {
              return "top-right";
            },
          },
          {
            key: "onAdd",
            value: function () {
              var e = this;
              (this.ctrl = document.createElement("div")),
                this.ctrl.classList.add(
                  "mapboxgl-ctrl",
                  "".concat("mapboxgl-ctrl", "-group")
                ),
                (this.list = document.createElement("div")),
                (this.list.style.display = "none"),
                this.list.classList.add("".concat("mapboxgl-ctrl", "-style")),
                (this.btn = document.createElement("button")),
                this.btn.classList.add("".concat("mapboxgl-ctrl", "-icon")),
                (this.btn.innerHTML = I.Layers.renderSvg());
              var t = !0,
                n = !1,
                r = undefined;
              try {
                for (
                  var i, o = this.styles[Symbol.iterator]();
                  !(t = (i = o.next()).done);
                  t = !0
                ) {
                  var c = i.value,
                    a = document.createElement("button");
                  a.classList.add(this.getClassForId(c.id)),
                    (a.innerText = c.title),
                    (a.dataset.id = c.id),
                    a.addEventListener("click", function (t) {
                      var n = t.srcElement,
                        r = n.dataset.id;
                      r && (e.dMap.setMapStyle(r), e.selectElement(n));
                    }),
                    c.id === this.defaultId && this.activate(a),
                    this.list.appendChild(a);
                }
              } catch (e) {
                (n = !0), (r = e);
              } finally {
                try {
                  t || null == o.return || o.return();
                } finally {
                  if (n) throw r;
                }
              }
              return (
                this.btn.addEventListener("click", function () {
                  return e.showList();
                }),
                document.addEventListener("click", function (t) {
                  e.ctrl.contains(t.target) || e.showList(!1);
                }),
                this.ctrl.appendChild(this.btn),
                this.ctrl.appendChild(this.list),
                this.ctrl
              );
            },
          },
          { key: "onRemove", value: function () {} },
          {
            key: "select",
            value: function (e) {
              var t = this.findElement(e);
              t && this.selectElement(t);
            },
          },
          {
            key: "getClassForId",
            value: function (e) {
              return "".concat("mapboxgl-ctrl", "-style-").concat(e);
            },
          },
          {
            key: "findElement",
            value: function (e) {
              if (this.list)
                return this.list.getElementsByClassName(
                  this.getClassForId(e)
                )[0];
            },
          },
          {
            key: "selectElement",
            value: function (e) {
              if ((this.showList(!1), this.list)) {
                var t = this.list.getElementsByClassName("active"),
                  n = !0,
                  r = !1,
                  i = undefined;
                try {
                  for (
                    var o, c = t[Symbol.iterator]();
                    !(n = (o = c.next()).done);
                    n = !0
                  ) {
                    var a = o.value;
                    this.activate(a, !1);
                  }
                } catch (e) {
                  (r = !0), (i = e);
                } finally {
                  try {
                    n || null == c.return || c.return();
                  } finally {
                    if (r) throw i;
                  }
                }
                this.activate(e);
              }
            },
          },
          {
            key: "activate",
            value: function (e) {
              var t =
                !(arguments.length > 1 && undefined !== arguments[1]) ||
                arguments[1];
              t ? e.classList.add("active") : e.classList.remove("active");
            },
          },
          {
            key: "display",
            value: function (e) {
              var t =
                !(arguments.length > 1 && undefined !== arguments[1]) ||
                arguments[1];
              e && (e.style.display = t ? "block" : "none");
            },
          },
          {
            key: "showList",
            value: function () {
              var e =
                !(arguments.length > 0 && undefined !== arguments[0]) ||
                arguments[0];
              this.display(this.list, e), this.display(this.btn, !e);
            },
          },
        ]),
        e
      );
    })();
  Function.prototype.$asyncbind = function e(n, r) {
    function i() {
      return o.apply(n, arguments);
    }
    Function.prototype.$asyncbind ||
      Object.defineProperty(Function.prototype, "$asyncbind", {
        value: e,
        enumerable: !1,
        configurable: !0,
        writable: !0,
      }),
      e.trampoline ||
        (e.trampoline = function (e, t, n, r, i) {
          return function o(c) {
            for (; c; ) {
              if (c.then) return (c = c.then(o, r)), i ? undefined : c;
              try {
                if (c.pop) {
                  if (c.length) return c.pop() ? t.call(e) : c;
                  c = n;
                } else c = c.call(e);
              } catch (e) {
                return r(e);
              }
            }
          };
        }),
      e.LazyThenable ||
        ((e.LazyThenable = (function () {
          function e(e) {
            return e && e instanceof Object && "function" == typeof e.then;
          }
          function t(n, r, i) {
            try {
              var o = i ? i(r) : r;
              if (n === o)
                return n.reject(new TypeError("Promise resolution loop"));
              e(o)
                ? o.then(
                    function (e) {
                      t(n, e);
                    },
                    function (e) {
                      n.reject(e);
                    }
                  )
                : n.resolve(o);
            } catch (e) {
              n.reject(e);
            }
          }
          function n(e) {}
          function r() {}
          function i(n, i) {
            var o = new r();
            try {
              this._resolver(
                function (r) {
                  return e(r) ? r.then(n, i) : t(o, r, n);
                },
                function (e) {
                  t(o, e, i);
                }
              );
            } catch (e) {
              t(o, e, i);
            }
            return o;
          }
          function o(e) {
            (this._resolver = e), (this.then = i);
          }
          return (
            (r.prototype = {
              resolve: n,
              reject: n,
              then: function (e, t) {
                (this.resolve = e), (this.reject = t);
              },
            }),
            (o.resolve = function (e) {
              return o.isThenable(e)
                ? e
                : {
                    then: function (t) {
                      return t(e);
                    },
                  };
            }),
            (o.isThenable = e),
            o
          );
        })()),
        (e.EagerThenable = e.Thenable = (e.EagerThenableFactory = function (e) {
          e =
            e ||
            ("object" ===
              ("undefined" == typeof process ? "undefined" : t(process)) &&
              process.nextTick) ||
            ("function" == typeof setImmediate && setImmediate) ||
            function (e) {
              setTimeout(e, 0);
            };
          var n = (function () {
            var t = [],
              n = 0,
              r = 1024;
            function i() {
              for (; t.length - n; ) {
                try {
                  t[n]();
                } catch (e) {}
                (t[n++] = undefined), n === r && (t.splice(0, r), (n = 0));
              }
            }
            return function (r) {
              t.push(r), t.length - n == 1 && e(i);
            };
          })();
          function r(e) {
            if (e) {
              var t = this;
              e(
                function (e) {
                  t.resolve(e);
                },
                function (e) {
                  t.reject(e);
                }
              );
            }
          }
          function i(e, t) {
            if ("function" == typeof e.y)
              try {
                var n = e.y.call(undefined, t);
                e.p.resolve(n);
              } catch (t) {
                e.p.reject(t);
              }
            else e.p.resolve(t);
          }
          function o(e, t) {
            if ("function" == typeof e.n)
              try {
                var n = e.n.call(undefined, t);
                e.p.resolve(n);
              } catch (t) {
                e.p.reject(t);
              }
            else e.p.reject(t);
          }
          return (
            (r.prototype = {
              resolve: function (e) {
                if (undefined === this.state) {
                  if (e === this)
                    return this.reject(
                      new TypeError("Attempt to resolve promise with self")
                    );
                  var r = this;
                  if (e && ("function" == typeof e || "object" === t(e)))
                    try {
                      var o = 0,
                        c = e.then;
                      if ("function" == typeof c)
                        return void c.call(
                          e,
                          function (e) {
                            o++ || r.resolve(e);
                          },
                          function (e) {
                            o++ || r.reject(e);
                          }
                        );
                    } catch (e) {
                      return void (o || this.reject(e));
                    }
                  (this.state = i),
                    (this.v = e),
                    r.c &&
                      n(function () {
                        for (var t = 0, n = r.c.length; t < n; t++)
                          i(r.c[t], e);
                      });
                }
              },
              reject: function (e) {
                if (undefined === this.state) {
                  (this.state = o), (this.v = e);
                  var t = this.c;
                  t &&
                    n(function () {
                      for (var n = 0, r = t.length; n < r; n++) o(t[n], e);
                    });
                }
              },
              then: function (e, t) {
                var i = new r(),
                  o = { y: e, n: t, p: i };
                if (undefined === this.state)
                  this.c ? this.c.push(o) : (this.c = [o]);
                else {
                  var c = this.state,
                    a = this.v;
                  n(function () {
                    c(o, a);
                  });
                }
                return i;
              },
            }),
            (r.resolve = function (e) {
              if (e && e instanceof r) return e;
              var t = new r();
              return t.resolve(e), t;
            }),
            (r.reject = function (e) {
              if (e && e instanceof r) return e;
              var t = new r();
              return t.reject(e), t;
            }),
            (r.version = "2.3.3-nodent"),
            r
          );
        })()));
    var o = this;
    switch (r) {
      case !0:
        return new e.Thenable(i);
      case 0:
        return new e.LazyThenable(i);
      case undefined:
        return (i.then = i), i;
      default:
        return function () {
          try {
            return o.apply(n, arguments);
          } catch (e) {
            return r(e);
          }
        };
    }
  };
  var X = (function (t) {
      function r() {
        var e, t;
        n(this, r);
        for (var i = arguments.length, c = new Array(i), a = 0; a < i; a++)
          c[a] = arguments[a];
        return (
          o(
            h((t = f(this, (e = u(r)).call.apply(e, [this].concat(c))))),
            "currentStyle",
            undefined
          ),
          o(h(t), "glMetaLayer", undefined),
          o(h(t), "fullscreenCtrl", undefined),
          t
        );
      }
      return (
        s(r, B),
        i(r, [
          {
            key: "getMap",
            value: function () {
              return this.map;
            },
          },
          {
            key: "createMap",
            value: function (e) {
              var t = this;
              this.currentStyle = e.style;
              var n = this.mapStyles.getMapStyle(this.currentStyle);
              return (
                (this.map = new mapboxgl.Map(
                  a({}, e.center && { center: [e.center.lng, e.center.lat] }, {
                    container: e.container,
                    hash: !1,
                    style: n.getUrl(y.GL),
                    zoom: e.zoom,
                  })
                )),
                this.map.on("load", function () {
                  t.initMarkerIcons();
                  var r = window.navigator.userAgent;
                  !1 === e.fullscreenControl ||
                    r.match(/iPad/i) ||
                    r.match(/iPhone/i) ||
                    ((t.fullscreenCtrl = new mapboxgl.FullscreenControl()),
                    t.map.addControl(t.fullscreenCtrl, "top-left")),
                    t.map.addControl(
                      new mapboxgl.NavigationControl(),
                      "top-left"
                    ),
                    !1 !== e.styleControl &&
                      t.map.addControl(new Q(t, n.id, t.mapStyles.getStyles())),
                    !1 !== e.geolocateControl &&
                      t.map.addControl(
                        new mapboxgl.GeolocateControl({
                          positionOptions: { enableHighAccuracy: !0 },
                          trackUserLocation: !0,
                        }),
                        "top-right"
                      ),
                    t.addLogo(),
                    !1 === e.oneFingerPan &&
                      new Y(function () {
                        return t.map.dragPan.disable();
                      }).addTo(t),
                    t.fire("load", { map: t });
                }),
                this.map
              );
            },
          },
          {
            key: "isLoaded",
            value: function () {
              return this.map && this.map.loaded();
            },
          },
          {
            key: "getContainer",
            value: function () {
              return this.map.getContainer();
            },
          },
          {
            key: "setMapStyle",
            value: function (e) {
              var t = this;
              if (this.currentStyle !== e) {
                var n = this.mapStyles.getMapStyleUrl(y.GL, e);
                n &&
                  ((this.currentStyle = e),
                  this.map.once("styledata", function () {
                    t.clearLayers(),
                      t.initMarkerIcons(),
                      t.restoreLayers(),
                      t.restoreTour();
                  }),
                  this.map.setStyle(n, { diff: !1 }));
              }
            },
          },
          {
            key: "resize",
            value: function () {
              this.map.resize();
            },
          },
          {
            key: "addMarker",
            value: function (e) {
              return this.createMarker(e).addTo(this.map);
            },
          },
          {
            key: "addLocationMarker",
            value: function (e) {
              var t = document.createElement("div");
              return (
                t.classList.add("mapboxgl-user-location-dot"),
                this.createMarker({ element: t, lat: e.lat, lng: e.lng }).addTo(
                  this.map
                )
              );
            },
          },
          {
            key: "addLine",
            value: function (e) {
              var t = this.initLayer(e, M.init),
                n = {
                  id: t.id,
                  layout: W.convertLineLayout(t.layout),
                  paint: W.convertLinePaint(t.paint),
                  source: { data: t.data, type: "geojson" },
                  type: "line",
                };
              return (
                this.map.addLayer(n),
                t.getLayout(function (e) {
                  return e.arrows;
                }) && this.addLineArrows(t),
                t
              );
            },
          },
          {
            key: "clearLine",
            value: function (e) {
              undefined !== this.map.getSource(e.id) &&
                (this.map.removeLayer(e.id),
                e.layout &&
                  e.layout.arrows &&
                  this.map.removeLayer("arrows_".concat(e.id)),
                this.map.removeSource(e.id));
            },
          },
          {
            key: "addLineArrows",
            value: function (e) {
              var t = M.init(e);
              this.map.addLayer({
                id: "arrows_".concat(t.id),
                layout: {
                  "symbol-placement": "line",
                  "symbol-spacing": 100,
                  "text-field": "→",
                  "text-keep-upright": !1,
                  "text-size": {
                    base: 1,
                    stops: [
                      [12, 24],
                      [24, 48],
                    ],
                  },
                },
                paint: {
                  "text-color": t.getPaintOr(function (e) {
                    return e.color;
                  }, "#3887be"),
                  "text-halo-color": "hsl(55, 11%, 96%)",
                  "text-halo-width": 3,
                },
                source: t.id,
                type: "symbol",
              });
            },
          },
          {
            key: "loadMetaLayer",
            value: function (e) {
              return new Promise(
                function (t, n) {
                  var r = this.initLayer(e, H.init);
                  return (
                    (r.id = "META"),
                    this.glMetaLayer && this.clearMetaLayer(r),
                    (this.glMetaLayer = new Z(this, r)),
                    t(this.glMetaLayer.addTo(this.map))
                  );
                }.$asyncbind(this)
              );
            },
          },
          {
            key: "clearMetaLayer",
            value: function (e) {
              this.glMetaLayer &&
                (this.glMetaLayer.removeFrom(this.map),
                (this.glMetaLayer = undefined));
            },
          },
          {
            key: "fitBounds",
            value: function (e) {
              var t = E.calculateBounds(e);
              t && this.map.fitBounds(t.toArray(), { padding: 20 });
            },
          },
          {
            key: "fitLayer",
            value: function (e) {
              e.data &&
                e.data.bbox &&
                this.map.fitBounds(e.data.bbox, { maxZoom: 16, padding: 40 });
            },
          },
          {
            key: "flyTo",
            value: function (e) {
              this.map.flyTo(e);
            },
          },
          {
            key: "addPopup",
            value: function (e) {
              var t = W.createPopup(e);
              return t.addTo(this.map), t;
            },
          },
          {
            key: "isFullscreen",
            value: function () {
              return (
                (this.fullscreenCtrl && this.fullscreenCtrl._fullscreen) || !1
              );
            },
          },
          {
            key: "setFullscreen",
            value: function (e) {
              this.fullscreenCtrl &&
                this.isFullscreen() !== e &&
                this.fullscreenCtrl._onClickFullscreen();
            },
          },
          {
            key: "createMarker",
            value: function (e) {
              var t = {
                  anchor: "bottom",
                  color: e.color,
                  element: e.element || K.createVectorMarkerDiv(e),
                  offset: [0, 7],
                },
                n = new mapboxgl.Marker(t).setLngLat(
                  new mapboxgl.LngLat(e.lng, e.lat)
                );
              return e.popup && n.setPopup(W.createPopup(e.popup)), n;
            },
          },
          {
            key: "initMarkerIcons",
            value: function () {
              var t = this;
              F.ALL.forEach(function (n) {
                var r = F.getKey(n.pinType || e.PinType.Default);
                if (!t.map.hasImage(r)) {
                  var i = I.Marker.create({
                      fill: n.color,
                      size: { x: 84, y: 104 },
                    }).renderSvg(n.symbol),
                    o = new Blob([i], { type: "image/svg+xml" }),
                    c = URL.createObjectURL(o),
                    a = new Image();
                  a.addEventListener(
                    "load",
                    function () {
                      URL.revokeObjectURL(c),
                        t.map.addImage(r, a, { pixelRatio: 2 });
                    },
                    { once: !0 }
                  ),
                    (a.src = c);
                }
              });
            },
          },
          {
            key: "addLogo",
            value: function () {
              this.map.addControl({
                getDefaultPosition: function () {
                  return "bottom-left";
                },
                onAdd: function (e) {
                  var t = new Image();
                  (t.src = "https://api.maps.destination.one/d-one_maps.png"),
                    (t.alt = "Ein Produkt der hubermedia GmbH"),
                    (t.style.border = "0"),
                    (t.style.width = "95px");
                  var n = document.createElement("a");
                  return (
                    (n.className = "mapboxgl-ctrl"),
                    (n.href = "https://www.destination.one"),
                    (n.target = "_blank"),
                    n.appendChild(t),
                    n
                  );
                },
                onRemove: function (e) {},
              });
            },
          },
        ]),
        r
      );
    })(),
    ee = (function () {
      function e() {
        n(this, e);
      }
      return (
        i(e, [
          {
            key: "canHandle",
            value: function (e) {
              return e instanceof mapboxgl.Map;
            },
          },
          {
            key: "isSupported",
            value: function () {
              return undefined !== mapboxgl && mapboxgl.supported();
            },
          },
          {
            key: "createMap",
            value: function (e) {
              return new X(e);
            },
          },
          {
            key: "useMap",
            value: function (e) {
              return new X(e);
            },
          },
        ]),
        e
      );
    })(),
    te = new ((function () {
      function e(t, r) {
        n(this, e),
          o(this, "factories", undefined),
          o(this, "defaultKey", undefined);
        var i = Object.keys(t);
        if (i.length < 1)
          throw new Error("Init failed: No MapFactories defined!");
        (this.factories = t), (this.defaultKey = r || i[0]);
      }
      return (
        i(e, [
          {
            key: "add",
            value: function (e, t) {
              this.factories[e] = t;
            },
          },
          {
            key: "findFactory",
            value: function (e) {
              for (var t in this.factories)
                if (t) {
                  var n = this.factories[t];
                  if (n && n.canHandle(e)) return n;
                }
            },
          },
          {
            key: "newMap",
            value: function (e) {
              var t =
                  arguments.length > 1 && undefined !== arguments[1]
                    ? arguments[1]
                    : this.defaultKey,
                n = this.factories[t];
              if (n) return n.createMap(e);
              throw new Error('MapType "'.concat(t, '" is not supported!'));
            },
          },
          {
            key: "useMap",
            value: function (e) {
              var t = this.findFactory(e);
              if (t) return t.useMap(e);
              throw new Error("This map object is not supported!");
            },
          },
          {
            key: "isSupported",
            value: function () {
              var e =
                  arguments.length > 0 && undefined !== arguments[0]
                    ? arguments[0]
                    : this.defaultKey,
                t = this.factories[e];
              return !!t && t.isSupported();
            },
          },
        ]),
        e
      );
    })())({ leaflet: new J(), mapboxgl: new ee() }, "mapboxgl"),
    ne = (function () {
      function e() {
        n(this, e);
      }
      return (
        i(e, null, [
          {
            key: "newMap",
            value: function (e) {
              var t =
                arguments.length > 1 && undefined !== arguments[1]
                  ? arguments[1]
                  : "mapboxgl";
              return te.newMap(e, t);
            },
          },
          {
            key: "useMap",
            value: function (e) {
              return te.useMap(e);
            },
          },
          {
            key: "isSupported",
            value: function () {
              var e =
                arguments.length > 0 && undefined !== arguments[0]
                  ? arguments[0]
                  : "mapboxgl";
              return te.isSupported(e);
            },
          },
        ]),
        e
      );
    })();
  return o(ne, "version", "1.0.1"), (e.Maps = ne), e;
})({});
