<template>
  <v-lazy :min-height="minHeight">
    <v-card
      :class="classes"
      :min-height="minHeight"
      :flat="rounded"
      :outlined="!rounded"
      :to="to"
      @click="onClick"
    >
      <template v-if="!item.ContentLink">
        <v-img
          :height="imgHeight"
          v-if="item.Image && item.Video"
          :src="
            $config.managerMedia +
            item.Image +
            `?resize&width=${$store.state.mediaWidth}`
          "
          :lazy-src="$config.managerMedia + item.Image + `?resize&height=20`"
        >
          <v-overlay absolute color="grey darken-4">
            <v-btn
              @click.prevent.stop="onClickVideo"
              @click.native.prevent.stop="onClickVideo"
              icon
              x-large
            >
              <v-icon>mdi-play</v-icon>
            </v-btn>
          </v-overlay>
        </v-img>
        <v-img
          :height="imgHeight"
          v-else
          :src="
            $config.managerMedia +
            item.Image +
            `?resize&width=${$store.state.mediaWidth}`
          "
          :lazy-src="$config.managerMedia + item.Image + `?resize&height=20`"
        />
        <v-divider></v-divider>
        <v-card-title :primary-title="item.Description">
          <span
            style="word-break: break-word"
            class="text-subtitle-2"
            :class="`${descriptionClasses} 
            ${item.Video && to ? 'text-decoration-underline' : ''}`"
          >
            {{ item.Header }}
          </span>
        </v-card-title>
        <v-card-text class="pt-0" v-if="item.Description">
          <span :class="descriptionClasses">{{ item.Description }}</span>
        </v-card-text>
      </template>
      <template v-else-if="item.ContentLink">
        <link-prevue card-width="100%" :url="item.ContentLink">
          <template slot-scope="props">
            <v-img
              v-if="item.Image && item.Image.length > 0"
              :height="imgHeight"
              :src="$config.managerMedia + item.Image"
              :lazy-src="$config.managerMedia + item.Image"
            />
            <v-img
              v-else
              :height="imgHeight"
              :src="props.img"
              :lazy-src="props.img"
            />
            <v-divider></v-divider>
            <v-card-title :primary-title="item.Description">
              <span
                style="word-break: break-word"
                class="text-subtitle-2"
                :class="descriptionClasses"
              >
                {{
                  item.Header
                    ? item.Header
                    : item.ContentLinkHeaderDelimiter
                    ? props.title.split(item.ContentLinkHeaderDelimiter)[
                        props.title.split(item.ContentLinkHeaderDelimiter)
                          .length - 1
                      ]
                    : props.title
                }}
              </span>
            </v-card-title>

            <v-card-text class="pt-0" v-if="item.Description">
              <span :class="descriptionClasses">
                {{ item.Description }}
              </span>
            </v-card-text>
          </template>
          <template slot="loading">
            <!-- set your custom loading -->
            <v-progress-linear indeterminate color="primary" />
          </template>
        </link-prevue>
      </template>
    </v-card>
  </v-lazy>
</template>

<script>
import LinkPrevue from "link-prevue";
import { getRoute, playVideo, goTo } from "@/util/contentpage";
import store from "@/store";

export default {
  components: {
    LinkPrevue,
  },
  props: {
    item: { type: Object, default: () => ({}) },
    lineClamp: { type: Boolean, default: true },
    small: { type: Boolean, default: false },
    maxWidth: { type: [Number, String], default: "" },
  },
  computed: {
    to() {
      if (this.item.ContentLink) {
        return undefined;
      }

      return getRoute(this.item);
    },
    descriptionClasses() {
      return this.lineClamp ? "line-clamp line-clamp-2" : "";
    },
    imgHeight() {
      return this.small
        ? this.$store.state.mediaHeight / 2
        : this.$store.state.mediaHeightText;
    },
    minHeight() {
      if (this.small) {
        return 215;
      }

      return 332;
    },
    classes() {
      return "";
    },
    hasDescriptionOrTitle() {
      return this.item.Header || this.item.Description;
    },
    rounded() {
      this.hasDescriptionOrTitle && this.ssmall;
    },
  },
  methods: {
    onClick() {
      if (this.item.ContentLink) {
        goTo(this.item.ContentLink);
      } else if (this.item.Video && !this.item.Page && !this.item.OnClick) {
        playVideo(this.item.Video);
      }
    },
    onClickVideo() {
      playVideo(this.item.Video);
    },
  },
};
</script>
