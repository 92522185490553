<template>
  <v-container fluid class="fill-height pa-0 ma-0">
    <v-fab-transition>
      <v-btn style="bottom: 15px !important" absolute fab large bottom right>
        <v-icon color="primary">mdi-map-search-outline</v-icon>
      </v-btn>
    </v-fab-transition>
    <div id="destMap" style="width: 100%; height: 100%">
      <strong v-if="error">destination.maps is not supported!</strong>
    </div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-fab-transition>
          <v-btn
            style="bottom: 15px !important"
            absolute
            fab
            large
            bottom
            right
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="primary">mdi-map-search-outline</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
      <v-card tile>
        <v-toolbar>
          <v-btn
            icon
            @click="
              dialog = false;
              resetTempValues();
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              class="text-caption"
              color="primary"
              text
              @click="
                dialog = false;
                resetValues();
              "
            >
              {{ $t("contentpage.destinationOne.resetFilter") }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-subheader>
          {{ $t("contentpage.destinationOne.filterResults") }}
        </v-subheader>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :placeholder="$t('contentpage.destinationOne.typeLocationName')"
                :label="$t('contentpage.destinationOne.fullTextSearch')"
                hide-details
                class="ma-0 pa-0"
                outlined
                append-icon="mdi-magnify"
                v-model="tempQ"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-combobox
                hide-details
                v-model="tempSelect"
                :items="items"
                item-text="text"
                item-value="value"
                label="Filter Type"
                outlined
              ></v-combobox>
            </v-col>
            <v-col cols="12">
              <v-text-field
                type="number"
                hide-details
                v-model="tempLimit"
                label="Limit Results"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-footer absolute>
          <v-btn
            block
            color="primary"
            large
            @click="
              dialog = false;
              filter();
            "
          >
            {{ $t("contentpage.destinationOne.done") }}
          </v-btn>
        </v-footer>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from "vue";
import "@/lib/d-maps/d-maps.js";
import "@/lib/d-maps/d-maps.css";
import "mapbox-gl/dist/mapbox-gl.css";

const defaultExperience = "demo_dahoam";
const defaultLng = "11.8348503112793";
const defaultLat = "49.9885387974919";
const defaultZoom = 8;
const defaultMkt = "de";
const defaultLicenseKey = "d432db10e1f921f4a8bb78b2b0210a9a0be574c0";

export default {
  props: {
    experience: {
      type: String,
      default: defaultExperience,
    },
    lng: {
      type: String,
      default: defaultLng,
    },
    lat: {
      type: String,
      default: defaultLat,
    },
    zoom: {
      type: Number,
      default: defaultZoom,
    },
    mkt: {
      type: String,
      default: defaultMkt,
    },
    licenseKey: {
      type: String,
      default: defaultLicenseKey,
    },
  },
  data: () => ({
    dMap: undefined,
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    select: "All",
    tempSelect: "All",
    error: false,
    items: [
      {
        value: "All",
        text: Vue.i18n.translate("contentpage.destinationOne.all"),
      },
      {
        value: "Hotel",
        text: Vue.i18n.translate("contentpage.destinationOne.hotel"),
      },
      {
        value: "Event",
        text: Vue.i18n.translate("contentpage.destinationOne.event"),
      },
      {
        value: "Gastro",
        text: Vue.i18n.translate("contentpage.destinationOne.gastro"),
      },
      {
        value: "Tour",
        text: Vue.i18n.translate("contentpage.destinationOne.tour"),
      },
      {
        value: "POI",
        text: Vue.i18n.translate("contentpage.destinationOne.poi"),
      },
      {
        value: "City",
        text: Vue.i18n.translate("contentpage.destinationOne.city"),
      },
      {
        value: "Area",
        text: Vue.i18n.translate("contentpage.destinationOne.area"),
      },
      {
        value: "Package",
        text: Vue.i18n.translate("contentpage.destinationOne.package"),
      },
      {
        value: "Article",
        text: Vue.i18n.translate("contentpage.destinationOne.article"),
      },
    ],
    q: "",
    tempQ: "",
    limit: 1000,
    tempLimit: 1000,
  }),
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      var mapOptions = {
        center: { lng: this.lng || defaultLng, lat: this.lat || defaultLat },
        container: "destMap",
        zoom: this.computedZoom || defaultZoom,
        experience: this.experience || defaultExperience,
        licenseKey: this.licenseKey || defaultLicenseKey,
      };

      if (window.Done.Maps.isSupported()) {
        this.dMap = window.Done.Maps.newMap(mapOptions);
        this.dMap.on("load", this.loadData);
        this.dMap.on("item-selected", this.itemSelected);
      } else {
        this.error = true;
      }
    },
    resetValues() {
      Object.assign(this.$data, this.$options.data());
      this.initData();
    },
    resetTempValues() {
      this.tempLimit = this.limit;
      this.tempQ = this.q;
      this.tempSelect = this.select;
    },
    itemSelected(event) {
      var map = event.map;
      var item = event.item;
      map.showItem(item);
      var popup = map.createPopup(item);
      map.showPopup(popup);
    },
    loadData() {
      this.dMap.showContent({
        experience: this.experience || defaultExperience,
        limit: this.limit,
        type: this.select,
        mkt: this.mkt || this.defaultMkt,
        licenseKey: this.licenseKey || this.defaultLicenseKey,
        q: this.q || undefined,
      });
    },
    filter() {
      this.select = this.tempSelect;
      this.q = this.tempQ;
      this.limit = this.tempLimit;
      this.loadData();
    },
  },
  computed: {
    computedZoom() {
      return parseInt(this.zoom);
    },
  },
  watch: {
    select(val) {
      this.tempSelect = val;
    },
    q(val) {
      this.tempQ = val;
    },
    limit(val) {
      this.tempLimit = val;
    },
  },
};
</script>
