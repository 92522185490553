<template>
  <v-container>
    <base-contentpage-card
      :item="{
        Header,
        Image,
        Description,
        Page,
        OnClick,
        Video,
        Action1Title,
        Action1OnClick,
        Action1Icon,
        Action2Title,
        Action2OnClick,
        Action2Icon,
        Action3Title,
        Action3OnClick,
        Action3Icon,
        Action4Title,
        Action4OnClick,
        Action4Icon,
        InfoButtonText,
      }"
    />
  </v-container>
</template>

<script>
import BaseContentpageCard from "@/components/contentpage/base/BaseContentpageCard";

export default {
  components: {
    BaseContentpageCard,
  },
  props: {
    Header: String,
    Image: String,
    Description: String,
    Page: String,
    OnClick: String,
    Video: String,
    Action1Title: String,
    Action1OnClick: String,
    Action1Icon: String,
    Action2Title: String,
    Action2OnClick: String,
    Action2Icon: String,
    Action3Title: String,
    Action3OnClick: String,
    Action3Icon: String,
    Action4Title: String,
    Action4OnClick: String,
    Action4Icon: String,
    InfoButtonText: String,
  },
};
</script>
