<template>
  <v-container fluid class="pa-0 ma-0">
    <template>
      <div class="pa-4" v-if="header && (header.Title || header.Description)">
        <h1 v-html="header.Title"></h1>
        <p v-html="header.Description"></p>
      </div>
      <v-list-group
        v-for="(item, mainIdx) in list"
        :key="`main-${mainIdx}`"
        v-model="item.active"
      >
        <template v-slot:activator>
          <v-list-item-title>{{ item.Title }}</v-list-item-title>
        </template>
        <v-list style="width: 100%">
          <template v-for="item in item.sublist">
            <v-list-item
              @click="
                selectedItem = item;
                dialog = true;
              "
              :key="item.Title"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.Title }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.Description }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="item.Title + '-divider'"></v-divider>
          </template>
        </v-list>
      </v-list-group>
    </template>
    <v-dialog v-model="dialog" scrollable>
      <v-card :disabled="status === 'fetching'" tile>
        <v-img
          :src="$config.managerMedia + selectedItem.Image"
          :lazy-src="$config.managerMedia + selectedItem.Image"
        ></v-img>
        <v-card-title>
          {{ selectedItem.Title }}
        </v-card-title>
        <v-card-text
          style="height: 150px"
          v-html="selectedItem.Description"
        ></v-card-text>
        <v-divider></v-divider>
        <div class="pa-4" v-if="status === 'failure'">
          <v-alert type="error" text>
            {{ $t("contentpage.sendRequestFailure") }}
          </v-alert>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">{{
            $t("general.close")
          }}</v-btn>
          <v-btn
            depressed
            :loading="status === 'fetching'"
            color="primary"
            @click="sendRequest({})"
          >
            {{ $t("contentpage.sendRequest") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="220" v-model="successDialog">
      <v-card>
        <div class="pa-4 d-flex align-center justify-center">
          <v-icon color="success" size="130">mdi-check-circle-outline</v-icon>
        </div>
        <v-card-text class="success--text font-weight-bold">
          {{ $t("contentpage.sendRequestSuccess") }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="successDialog = false" color="primary" text>{{
            $t("general.ok")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    dialog: false,
    selectedItem: {},
    status: undefined,
    successDialog: false,
    reservation: undefined,
  }),
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    subList: {
      type: Array,
      default: () => [],
    },
    header: {
      type: Object,
      default: () => ({}),
    },
  },
  async mounted() {
    this.reservation = await this.getReservation();
  },
  methods: {
    async getReservation() {
      this.status = "fetching";
      const {
        apiKey = "9a01f164-307f-4c7f-b4da-1368961ec264",
        hotelId = "444",
        token = "YXRfdmlzaW9uX3NhbmRib3g6Qmh1ODlvbC4=",
      } = this.header;

      let reservation = undefined;
      try {
        reservation = await axios.get(
          `https://rapi.aliceapp.com/staff/v1/hotels/${hotelId}/reservations?apikey=${apiKey}&room=${this.$store.state.session.authResult.room}&statuses=Approved`,
          {
            headers: { Authorization: `Basic ${token}` },
          }
        );
        this.status = "success";
        return reservation.data[0];
      } catch (error) {
        if (error.response.status === 429) {
          // eslint.disable-next-line no-console
          console.error(
            "request limit reached, trying automatically again in 30 seconds"
          );
          setTimeout(() => {
            this.getReservation();
          }, 30000);
        } else {
          // eslint.disable-next-line no-console
          console.error(
            `https://rapi.aliceapp.com/staff/v1/hotels/${hotelId}/reservations?apikey=${apiKey}&room=${this.$store.state.session.authResult.room} failed with error: ${error}`
          );
          this.status = "failure";
        }
      }
    },
    async sendRequest({ item = this.selectedItem }) {
      this.status = "fetching";
      const {
        apiBaseUrl = "https://rapi.aliceapp.com/guest",
        apiKey = "9a01f164-307f-4c7f-b4da-1368961ec264",
        hotelId = "444",
      } = this.header;
      try {
        await axios.post(
          `https://rapi.aliceapp.com/guest/v1/hotels/${hotelId}/reservations/${this.reservation.uuid}/serviceRequests?apikey=${apiKey}`,
          {
            dueDate: new Date(),
            facilityId: "6561",
            facilityName: "Generic Facility",
            info: `ID:${item._id}\nTITLE:${item.Title}\nDESC:${item.Description}`,
            lang: this.$store.state.language.selectedLanguage.lang_key,
            requester: "Guest",
            roomNumber: this.$store.state.session.authResult.room,
            serviceId: "21712",
            serviceName: "Generic Service",
          }
        );
        this.status = "success";
        this.dialog = false;
        this.successDialog = true;
      } catch (error) {
        if (error.response.status === 429) {
          // eslint.disable-next-line no-console
          console.error(
            "request limit reached, trying automatically again in 30 seconds"
          );
          setTimeout(() => {
            this.sendRequest({ item });
          }, 30000);
          this.dialog = false;
        } else {
          // eslint.disable-next-line no-console
          console.error(
            `https://rapi.aliceapp.com/guest/v1/hotels/${hotelId}/reservations/${this.reservation.uuid}/serviceRequests?apikey=${apiKey} failed with error: ${error}`
          );
          this.status = "failure";
        }
      }
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.status = undefined;
        this.selectedItem = {};
      }
    },
  },
};
</script>
