<template>
  <v-lazy :min-height="minHeight">
    <v-card class="text-center" flat :to="to" @click="onClick">
      <template v-if="item.Image && !item.ContentLink">
        <v-avatar
          style="border: 2px solid white"
          class="elevation-1 rounded-circle"
          size="80"
        >
          <v-img
            :height="imgHeight"
            v-if="item.Image && item.Video"
            :src="
              $config.managerMedia +
              item.Image +
              `?resize&height=${$store.state.mediaWidth}`
            "
            :lazy-src="$config.managerMedia + item.Image + `?resize&height=20`"
          >
            <v-overlay absolute color="grey darken-4">
              <v-btn
                @click.prevent.stop="onClickVideo"
                @click.native.prevent.stop="onClickVideo"
                icon
                x-large
              >
                <v-icon>mdi-play</v-icon>
              </v-btn>
            </v-overlay>
          </v-img>

          <v-img
            :height="imgHeight"
            v-else-if="item.Image"
            :src="
              $config.managerMedia +
              item.Image +
              `?resize&width=${$store.state.mediaWidth}`
            "
            :lazy-src="$config.managerMedia + item.Image + `?resize&height=20`"
          />
        </v-avatar>
        <v-card-title
          class="px-0 py-0 pt-2 text-center"
          :primary-title="item.Description"
        >
          <span
            style="word-break: break-word; width: 100%"
            class="text-subtitle-2"
            :class="`${descriptionClasses} 
            ${item.Video && to ? 'text-decoration-underline' : ''}`"
          >
            {{ item.Header }}
          </span>
        </v-card-title>
        <v-card-text class="pb-0" v-if="item.Description">
          <span :class="descriptionClasses">{{ item.Description }}</span>
        </v-card-text>
      </template>
      <template v-else-if="item.ContentLink">
        <link-prevue card-width="100%" :url="item.ContentLink">
          <template slot-scope="props">
            <v-avatar
              style="border: 2px solid white"
              class="elevation-1"
              size="80"
            >
              <v-img
                v-if="item.Image && item.Image.length > 0"
                :height="imgHeight"
                :src="$config.managerMedia + item.Image"
                :lazy-src="$config.managerMedia + item.Image"
              />
              <v-img
                v-else
                :height="imgHeight"
                :src="props.img"
                :lazy-src="props.img"
              />
            </v-avatar>
            <v-card-title
              class="px-0 py-0 pt-2 text-center"
              :primary-title="item.Description"
            >
              <span
                style="word-break: break-word; width: 100%"
                class="text-subtitle-2"
                :class="descriptionClasses"
              >
                {{
                  item.Header
                    ? item.Header
                    : item.ContentLinkHeaderDelimiter
                    ? props.title.split(item.ContentLinkHeaderDelimiter)[
                        props.title.split(item.ContentLinkHeaderDelimiter)
                          .length - 1
                      ]
                    : props.title
                }}
              </span>
            </v-card-title>

            <v-card-text class="pb-0" v-if="item.Description">
              <span :class="descriptionClasses">
                {{ item.Description }}
              </span>
            </v-card-text>
          </template>
          <template slot="loading">
            <!-- set your custom loading -->
            <v-progress-linear indeterminate color="primary" />
          </template>
        </link-prevue>
      </template>
    </v-card>
  </v-lazy>
</template>

<script>
import LinkPrevue from "link-prevue";
import { getRoute, playVideo, goTo } from "@/util/contentpage";
import store from "@/store";

export default {
  components: {
    LinkPrevue,
  },
  props: {
    item: { type: Object, default: () => ({}) },
    lineClamp: { type: Boolean, default: true },
    small: { type: Boolean, default: false },
    maxWidth: { type: [Number, String], default: "" },
  },
  computed: {
    to() {
      return getRoute(this.item);
    },
    descriptionClasses() {
      return this.lineClamp ? "line-clamp line-clamp-2" : "";
    },
    imgHeight() {
      return this.small
        ? this.$store.state.mediaHeight / 2
        : this.$store.state.mediaHeightText;
    },
    minHeight() {
      return 132;
    },
    classes() {
      return "";
    },
    hasDescriptionOrTitle() {
      return this.item.Header || this.item.Description;
    },
    rounded() {
      this.hasDescriptionOrTitle && this.ssmall;
    },
  },
  methods: {
    onClick(url) {
      if (this.item.ContentLink) {
        goTo(url);
      } else if (this.item.Video && !this.item.Page && !this.item.OnClick) {
        playVideo(this.item.Video);
      }
    },
    onClickVideo() {
      playVideo(this.item.Video);
    },
  },
};
</script>
