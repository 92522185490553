<template>
  <v-container
    class="pa-0"
    v-bind="{ [`grid-list-${$vuetify.breakpoint.name}`]: true }"
  >
    <template v-for="(item, index) in $store.state.picturePoolGallery.images">
      <v-img :key="'img-' + index" v-if="item" :src="item" :lazy-src="item">
      </v-img>
      <v-divider :key="'divider-' + index"></v-divider>
    </template>
  </v-container>
</template>

<script>
export default {
  props: ["PictureFolder"],
  data() {
    return {
      dialog: false,
      dialogImg: undefined,
    };
  },
  created() {
    let folderPattern = this.PictureFolder.split("/")[2];
    this.$store.dispatch("picturePoolGallery/getImages", {
      folder_pattern: folderPattern,
    });
  },
};
</script>
