<template>
  <v-container fluid class="pa-0 ma-0">
    <v-tabs color="primary" v-model="activeTab" grow show-arrows>
      <v-tab v-for="(item, index) in list" :key="index" color="primary">
        {{ item.Header }}
      </v-tab>
      <v-tab-item v-for="(item, index) in list" :key="index">
        <v-container class="pa-0 ma-0" fluid>
          <row>
            <template v-for="(subItem, subIndex) in item.sublist">
              <v-col
                v-if="
                  subItem.Header ||
                  (subItem.Description &&
                    (subItem.Header.length > 0 ||
                      subItem.Description.length > 0))
                "
                :key="subIndex"
                cols="12"
                lg="6"
              >
                <base-contentpage-card :item="subItem" />
              </v-col>
              <v-col
                class="pa-0"
                v-if="$vuetify.breakpoint.mdAndDown"
                :key="'seperator-' + subIndex"
                cols="12"
              >
                <base-seperator :item="item" />
              </v-col>
            </template>
          </row>
        </v-container>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import BaseContentpageCard from "@/components/contentpage/base/BaseContentpageCard";
import BaseSeperator from "@/components/contentpage/base/BaseSeperator";

export default {
  components: {
    BaseContentpageCard,
    BaseSeperator,
  },
  props: {
    list: Array,
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  methods: {
    nextTab() {
      this.activeTab =
        this.activeTab < this.list.length - 1 ? this.activeTab + 1 : 0;
    },
    prevTab() {
      this.activeTab =
        this.activeTab <= 0 ? this.list.length - 1 : this.activeTab - 1;
    },
  },
};
</script>
