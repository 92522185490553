<template>
  <v-container fluid class="pa-0 ma-0 fill-height">
    <template v-if="!callInProgress">
      <template v-if="header && header.backgroundImage">
        <v-img
          ref="img"
          :src="
            $config.managerMedia +
            header.backgroundImage +
            `?resize&width=${$store.state.mediaWidth}`
          "
          height="300px"
          dark
        >
          <v-row align="end" v-if="header.Header" class="fill-height">
            <v-container class="py-0">
              <v-card-title class="white--text">
                <div class="display-1">{{ header.Header }}</div>
              </v-card-title>
            </v-container>
          </v-row>
        </v-img>
      </template>
      <v-card-text v-if="header.Description">
        {{ header.Description }}
      </v-card-text>
      <v-list style="width: 100%">
        <template v-for="item in list">
          <v-list-item :key="item.Uri + item.Text">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.Text }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                @click="call({ uri: item.Uri, text: item.Text })"
                icon
                color="primary"
              >
                <v-icon>mdi-phone</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="item.Uri + item.Text + '-divider'"></v-divider>
        </template>
      </v-list>
    </template>
    <v-container class="fill-height">
      <v-row v-if="callInProgress" align="center" class="fill-height">
        <v-col cols="12">
          <v-card-title class="text-center">{{ callStatus }}</v-card-title>
        </v-col>
        <v-col cols="12">
          <v-row justify="center">
            <v-btn fab large color="red" dark @click="callInProgress = false">
              <v-icon>mdi-phone-hangup</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog fullscreen v-model="callInProgress" persistent>
      <v-card tile class="fill-height text-center">
        <v-card-title>
          <h1 style="width: 100%" class="headline">
            {{ callingText }}
          </h1>
        </v-card-title>
        <v-card-text>
          {{ callStatus }}
        </v-card-text>
        <div style="height: 350px"></div>
        <v-card-actions>
          <v-row justify="center">
            <v-btn fab large color="red" dark @click="hangUp">
              <v-icon>mdi-phone-hangup</v-icon>
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import JsSIP from "jssip";

export default {
  data: () => ({
    callInProgress: false,
    callStatus: "",
    callingText: "",
    ua: null,
  }),
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    header: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    call({ uri, text }) {
      this.callingText = text;
      const wsHost = this.header.WebsocketHost;
      const selfUri = this.header.UriSchema.replace(
        "{room}",
        this.$store.state.session.authResult.room
      );
      const password = this.header.passwordschema.replace(
        "{room}",
        this.$store.state.session.authResult.room
      );

      var socket = new JsSIP.WebSocketInterface(wsHost);
      var configuration = {
        sockets: [socket],
        uri: selfUri,
        password,
      };

      this.ua = new JsSIP.UA(configuration);

      this.ua.start();

      // Register callbacks to desired call events
      this.callInProgress = true;

      var eventHandlers = {
        connecting: (e) => {
          this.callStatus += "connecting";
        },
        connected: (e) => {
          this.callStatus += " - connected";
        },
        disconnected: (e) => {
          this.callStatus += " - disconnected";
        },
        registered: (e) => {
          this.callStatus += " - registered";
        },
        unregistered: (e) => {
          this.callStatus += " - unregistered";
        },
        registrationFailed: (e) => {
          this.callStatus += " - registrationFailed";
        },
        progress: (e) => {
          this.callStatus += " - progress";
        },
        failed: (e) => {
          this.callStatus += " - failed";
        },
        ended: (e) => {
          this.callStatus += " - ended";
        },
        confirmed: (e) => {
          this.callStatus += "confirmed";
        },
      };

      var options = {
        eventHandlers: eventHandlers,
        mediaConstraints: { audio: true, video: false },
      };

      var session = this.ua.call(uri, options);
    },
    hangUp() {
      this.ua.stop();
      this.callInProgress = false;
    },
  },
};
</script>
