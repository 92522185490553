<template>
  <v-container
    v-if="items.length > 0"
    class="pa-0"
    v-bind="{ [`grid-list-md`]: true }"
    fluid
  >
    <v-layout class="ma-0 pa-0" style="overflow: hidden; overflow-x: auto">
      <v-flex
        :style="{
          'min-width': getMultipleCardWidth(items.length),
          'max-width': getMultipleCardWidth(items.length),
        }"
        v-for="subItem in items"
        :key="subItem.Header"
      >
        <base-contentpage-landingpage-card-rounded
          :maxWidth="getMultipleCardWidth(items.length)"
          small
          :item="subItem"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import BaseContentpageLandingpageCardRounded from "@/components/contentpage/base/BaseContentpageLandingpageCardRounded";

export default {
  components: {
    BaseContentpageLandingpageCardRounded,
  },
  props: {
    items: { type: Array, default: () => [] },
  },
  methods: {
    getMultipleCardWidth(cardsCnt) {
      if (cardsCnt * 120 <= this.$vuetify.breakpoint.width) {
        return `${80 / cardsCnt}%`;
      }

      var divisor = Math.round(this.$vuetify.breakpoint.width / 120) + 0.925;
      return this.$vuetify.breakpoint.width / divisor + "px";
    },
  },
};
</script>
