var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-lazy',{attrs:{"min-height":_vm.item.Image || _vm.item.Video ? _vm.$store.state.mediaHeight : ''}},[_c('v-card',{attrs:{"outlined":"","height":"100%"}},[(_vm.item.Image && _vm.item.Video)?_c('v-img',{ref:"img",attrs:{"src":_vm.$config.managerMedia +
        _vm.item.Image +
        `?resize&width=${_vm.$store.state.mediaWidth}`,"lazy-src":_vm.$config.managerMedia + _vm.item.Image + `?resize&height=20`,"height":_vm.$store.state.mediaHeightText}},[_c('v-overlay',{attrs:{"absolute":"","color":"grey darken-4"}},[_c('v-btn',{attrs:{"icon":"","x-large":""},on:{"click":_vm.onClickVideo}},[_c('v-icon',[_vm._v("mdi-play")])],1)],1)],1):(_vm.item.Image)?_c('v-img',{ref:"img",attrs:{"src":_vm.$config.managerMedia +
        _vm.item.Image +
        `?resize&width=${_vm.$store.state.mediaWidth}`,"lazy-src":_vm.$config.managerMedia + _vm.item.Image + `?resize&height=20`,"height":_vm.$store.state.mediaHeightText}}):_vm._e(),(_vm.item.Header || _vm.item.Description)?_c('v-card-title',{staticClass:"title primary--text",staticStyle:{"word-break":"break-word"},attrs:{"primary-title":""},domProps:{"innerHTML":_vm._s(_vm.item.Header)}}):_vm._e(),(_vm.item.Description)?_c('v-card-text',{staticClass:"text-body-1",staticStyle:{"white-space":"pre-wrap"},domProps:{"innerHTML":_vm._s(_vm.item.Description)}}):_vm._e(),(
        _vm.item.Page ||
        _vm.item.OnClick ||
        _vm.item.Action1Title ||
        _vm.item.Action2Title ||
        _vm.item.Action3Title ||
        _vm.item.Action4Title
      )?_c('v-card-actions',{staticStyle:{"overflow-x":"auto"}},[(_vm.item.Page || (_vm.item.OnClick && _vm.item.InfoButtonText !== ' '))?_c('v-btn',{attrs:{"color":"primary","text":"","to":_vm.to},domProps:{"textContent":_vm._s(_vm.item.InfoButtonText || _vm.$t('app.info'))}}):_vm._e(),(_vm.item.Action1Title)?_c('v-btn',{attrs:{"color":"primary","text":"","to":_vm.toAction1}},[(_vm.item.Action1Icon)?_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.item.Action1Icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.item.Action1Title)+" ")],1):_vm._e(),(_vm.item.Action2Title)?_c('v-btn',{attrs:{"color":"primary","text":"","to":_vm.toAction2}},[(_vm.item.Action2Icon)?_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.item.Action2Icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.item.Action2Title)+" ")],1):_vm._e(),(_vm.item.Action3Title)?_c('v-btn',{attrs:{"color":"primary","text":"","to":_vm.toAction3}},[(_vm.item.Action3Icon)?_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.item.Action3Icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.item.Action3Title)+" ")],1):_vm._e(),(_vm.item.Action4Title)?_c('v-btn',{attrs:{"color":"primary","text":"","to":_vm.toAction4}},[(_vm.item.Action4Icon)?_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.item.Action4Icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.item.Action4Title)+" ")],1):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }